export class Bar {
  class?: string;
  x?: number;
  y?: number;
  width?: number;
  height?: number;
  rx?: number;
  ry?: number;
  clip?: string;
  fill?: string;

  label: string;
  value: number;
  count: number;
  showCount: boolean;
  options: any;

  constructor(label: any, value: any, count: number = null, options: any = {}, showCount: boolean = false) {
    this.label = label;
    this.value = value;
    this.count = count;
    this.options = options;
    this.showCount = showCount;
  }
}
