import {
  Component,
  Input,
  ElementRef,
  OnChanges,
  AfterViewInit,
  ViewChild,
  Output,
  EventEmitter,
  OnDestroy,
  ViewChildren,
  QueryList
} from '@angular/core';
import { D3Service } from 'src/app/lib/d3/service';
import { TimelinesChart } from 'src/app/lib/d3/models/charts/timelines.chart';
import * as erdm from 'element-resize-detector';
import { DropDownIconComponent } from 'src/app/components/drop-down-icon/drop-down-icon.component';

@Component({
  selector: 'timelineschart',
  templateUrl: './timelineschart.component.html',
  styleUrls: ['./timelineschart.component.scss']
})
export class TimelinesChartVisualComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input()
  events: any[] = [];

  @Input()
  eventsMenu: boolean = false;

  @Input() endDay: string;

  @Input()
  dayView: any = null;

  @Input()
  overlayMessage: string; // own overlay message, similar as loading or no event message

  @Output()
  view: any = new EventEmitter();

  @Output()
  event: any = new EventEmitter();

  @ViewChild('canvas')
  canvas: ElementRef<HTMLDivElement>;

  @ViewChild('eventDropDown') eventDropDown: DropDownIconComponent;
  @ViewChildren('eventDropDownItem') eventDropDownItem: QueryList<ElementRef<HTMLLIElement>>;

  chart: TimelinesChart;
  positionTimeout: any;
  showTimeout: any;
  data: any[] = [];
  eventCount = 0;

  margins: any = {
    top: 60,
    right: 40,
    bottom: 40,
    left: 60
  };

  xAxis: any[] = [];
  yAxis: any[] = [];

  tooltipDay: any = {
    show: false,
    style: { 'top.px': 0, 'left.px': 0 }
  };

  tooltipEvent: any = {
    show: false,
    style: { 'top.px': 0, 'left.px': 0 },
    data: []
  };

  eventsList = {
    style: { 'top.px': 0, 'left.px': 0 },
    data: { allEvents: [] }
  };

  erd: any = erdm({ strategy: 'scroll' });

  constructor(private d3: D3Service) {
    this.chart = this.d3.generate('timelineschart');
  }

  ngAfterViewInit(): void {
    this.erd.listenTo(this.canvas.nativeElement, () => {
      this.render();
    });
  }

  ngOnChanges(): void {
    if (this.canvas) this.render();
  }

  render(): void {
    this.chart.update(
      this.events,
      this.canvas.nativeElement.clientWidth,
      this.canvas.nativeElement.clientHeight,
      this.margins,
      this.dayView,
      this.endDay
    );

    this.xAxis = this.chart.xAxis();
    this.yAxis = this.chart.yAxis();

    this.data = this.chart.calculateData();
    this.eventCount = this.data.reduce((acc: number, value: { events: unknown[] }) => acc + value.events.length, 0);
  }

  changeView(day: any = null, event: MouseEvent & { stopDayOpening?: boolean } = null): void {
    if (event?.stopDayOpening) {
      return;
    }
    this.tooltipEvent.show = false;

    if (!this.dayView) {
      this.view.emit({ ...day, events: day.allEvents });

      this.positionTooltipDay(null, false);
      this.positionTooltipEvent(null, null, false);
    }
  }

  positionTooltipDay(day: any, mode: boolean): void {
    if (day) {
      this.tooltipDay.style['left.px'] = day.center + this.margins.left;
      this.tooltipDay.text = day.text;
    }

    this.tooltipDay.show = mode;
  }

  positionTooltipEvent(day: any, event: any, mode: boolean): void {
    clearTimeout(this.positionTimeout);
    clearTimeout(this.showTimeout);

    if (mode) {
      if (day && event) {
        event.hover = mode;
        this.tooltipEvent.style['left.px'] = day.center + this.margins.left;
        this.tooltipEvent.style['top.px'] = 20 + event.y + this.margins.top;
        this.tooltipEvent.data = event;
      }

      this.positionTimeout = setTimeout(() => {
        const id = event?.eventType === 'cluster' ? 'tooltip-cluster' : 'tooltip-event';
        const tooltipPosition = this.canvas.nativeElement.querySelector(`#${id}`).getBoundingClientRect();
        const canvasPosition = this.canvas.nativeElement.getBoundingClientRect();

        if (tooltipPosition.left <= canvasPosition.left) {
          this.tooltipEvent.style['left.px'] =
            day.center + this.margins.left + (canvasPosition.left - tooltipPosition.left);
        }

        if (tooltipPosition.left + tooltipPosition.width >= canvasPosition.left + canvasPosition.width) {
          this.tooltipEvent.style['left.px'] =
            day.center +
            this.margins.left -
            (tooltipPosition.left + tooltipPosition.width - (canvasPosition.left + canvasPosition.width));
        }

        if (tooltipPosition.top + tooltipPosition.height >= canvasPosition.top + canvasPosition.height) {
          this.tooltipEvent.style['top.px'] = this.margins.top + event.y - tooltipPosition.height - 20;
        }

        this.showTimeout = setTimeout(() => {
          this.tooltipEvent.show = mode;
        }, 10);
      }, 10);
    } else {
      this.tooltipEvent.show = mode;

      if (event) {
        event.hover = mode;
      }
    }
  }

  showEventList(e: any, day: any): void {
    if (day) {
      e.stopDayOpening = true;
      this.eventDropDown.setOpenState(false);

      if (day.allEvents.length > 1) {
        this.eventsList.data = {
          ...day,
          allEvents: day.allEvents?.sort((a, b) => (a.createdAt < b.createdAt ? -1 : a.createdAt > b.createdAt ? 1 : 0))
        };

        setTimeout(() => {
          const newItem = document.createElement('span');
          newItem.style.fontSize = '0.7rem';
          newItem.style.fontWeight = '400';
          document.body.appendChild(newItem);

          const listWidth = Math.max(
            ...this.eventDropDownItem.map((item) => {
              newItem.textContent = item.nativeElement.textContent;
              return newItem.getBoundingClientRect().width;
            })
          );

          document.body.removeChild(newItem);
          this.eventsList.style['left.px'] =
            this.calculateXOverflowOffset(e.clientX, listWidth) || day.center + this.margins.left - 16 - listWidth / 2;
          this.eventsList.style['top.px'] = day.height + this.margins.top + 10;
          this.eventDropDown.setOpenState(true);
        }, 0);
      }

      if (day.allEvents.length === 1) {
        this.selectEvent(day.allEvents[0]);
      }
    }
  }

  showClusterEventList(e: any, cluster: any, day: any): void {
    if (!this.dayView) {
      return;
    }

    e.stopDayOpening = true;
    this.eventDropDown.setOpenState(false);

    this.eventsList.data = {
      allEvents: cluster.events?.sort((a, b) => (a.createdAt < b.createdAt ? -1 : a.createdAt > b.createdAt ? 1 : 0))
    };

    setTimeout(() => {
      const newItem = document.createElement('span');
      newItem.style.fontSize = '0.7rem';
      newItem.style.fontWeight = '400';
      document.body.appendChild(newItem);

      const listWidth = Math.max(
        ...this.eventDropDownItem.map((item) => {
          newItem.textContent = item.nativeElement.textContent;
          return newItem.getBoundingClientRect().width;
        })
      );

      document.body.removeChild(newItem);
      this.eventsList.style['left.px'] =
        this.calculateXOverflowOffset(e.clientX, listWidth) || day.center + this.margins.left - 16 - listWidth / 2;
      this.eventsList.style['top.px'] = cluster.y + this.margins.top;
      this.eventDropDown.setOpenState(true);
    }, 0);
  }

  selectEvent(event: any, e: any = null): void {
    this.event.emit(event);

    if (e) {
      e.stopDayOpening = true;
    }
  }

  private calculateXOverflowOffset(absoluteXPos: number, listWidth: number): number {
    const maxWidth = window.innerWidth;
    if (maxWidth - absoluteXPos - this.margins.left - 16 <= listWidth / 2) {
      return maxWidth - this.canvas.nativeElement.getBoundingClientRect().x - listWidth - this.margins.left - 16;
    }
    return 0;
  }

  ngOnDestroy(): void {
    clearTimeout(this.positionTimeout);
    clearTimeout(this.showTimeout);

    if (this.canvas) {
      this.erd.uninstall(this.canvas.nativeElement);
    }
  }
}
