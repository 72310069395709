<modal mode="compact" (overlayAction)="closeModal()">
  <ng-container title>{{ 'devices.device.ohp.details' | translate }}</ng-container>
  <ng-container content>
    <ng-container *ngIf="!hasOhpDetails">
      <div class="noData">{{ 'devices.device.ohp.noData' | translate }}</div>
    </ng-container>
    <ng-container *ngIf="hasOhpDetails">
      <div class="list" *ngFor="let detail of ohpDetails | keyvalue">
        <div class="key">{{ 'devices.device.ohp.' + detail.key | translate }}</div>
        <div class="value">{{ detail.value }}</div>
      </div>
      <div class="notificationsTitle" *ngIf="hasOhpNotificationDetails">
        {{ 'devices.device.ohp.notificationDetails' | translate }}
      </div>
      <div class="list" *ngFor="let detail of ohpNotificationDetails | keyvalue">
        <div class="key">{{ 'devices.device.ohp.' + detail.key | translate }}</div>
        <div class="value">{{ detail.value }}</div>
      </div>
    </ng-container>
  </ng-container>
  <ng-container controls>
    <button class="tertiary light" (click)="closeModal()">
      {{ 'health.networkInformation.modal.close' | translate }}
    </button>
  </ng-container>
</modal>
