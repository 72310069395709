<ng-container>
  <header placeholder="header.searchCustomers" [enableDeleteCustomer]="true"></header>

  <side-menu
    class="desktop"
    [class.volt]="isVolt()"
    *ngIf="(plume.permissions | async)?.uiFeatures.sidebars"
  ></side-menu>

  <div
    id="customer-view"
    [class.sideMenu]="(plume.permissions | async)?.uiFeatures.sidebars"
    [class.volt]="isVolt()"
    cdk-scrollable
    *ngIf="!reloadComponents"
  >
    <router-outlet></router-outlet>
  </div>

  <div id="volt" [class.sideMenu]="(plume.permissions | async)?.uiFeatures.sidebars" *ngIf="isVolt()">
    <header-volt [powerState]="powerState"></header-volt>
  </div>
  <customerinfo *ngIf="(plume.permissions | async)?.uiFeatures.sidebars"></customerinfo>
</ng-container>

<help></help>

<modal mode="compact" (overlayAction)="migrate = $event" *ngIf="migrate">
  <ng-container title>{{ 'customer.migrated.title' | translate }}</ng-container>
  <ng-container content>
    {{
      'customer.migrated.message' | translate : { cloud: migrate.cloud.name, date: migrate.date, time: migrate.time }
    }}
  </ng-container>
  <ng-container controls>
    <button class="tertiary light" (click)="migrate = null">
      {{ 'customer.migrated.close' | translate }}
    </button>
    <button class="super-primary" (click)="gotoMigrated()">
      {{ 'customer.migrated.switchClouds' | translate : { cloud: migrate.cloud.name } }}
    </button>
  </ng-container>
</modal>
