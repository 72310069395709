export class Tick {
  class: string;
  showgrid: boolean;
  grid: any = { x1: 0, x2: 0, y1: 0, y2: 0 };
  text: {
    x: number;
    y: number;
    dx: number;
    dy: number;
    transform: string;
    fill?: string;
    textAnchor?: 'start' | 'middle' | 'end';
  } = { x: 0, y: 0, dx: 0, dy: 0, transform: '' };
  value: any;
  transform: string;

  constructor(axis: string, value: any, transform: any, text?: any, showgrid: boolean = false, grid?: any) {
    this.class = 'tick ' + axis;
    this.value = value;
    this.transform = transform;

    this.showgrid = showgrid;

    if (grid) {
      this.grid = { ...this.grid, ...grid };
    }

    if (text) {
      this.text = { ...this.text, ...text };
    }
  }
}
