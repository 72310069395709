<div class="row" id="conf-firmware">
  <div class="col-xs-12">
    <collapsiblebox [show]="expand" [collapsible]="true" (toggle)="toggleExpand()">
      <div header>{{ 'configurations.firmware.title' | translate }}</div>
      <div container *ngIf="expand">
        <div class="columns">
          <div class="column" *ngrxLet="firmwareUpgrade$ as firmwareUpgrade">
            <div class="row" *ngIf="firmwareUpgrade?.activeVersionMatrixId">
              <div class="key">{{ 'configurations.firmware.activeVersionMatrix' | translate }}</div>
              <div class="value">
                <div>{{ firmwareUpgrade?.activeVersionMatrixId }}</div>
                <icon name="icon-info" class="size-0_8" (click)="showMatrixDetails = true"></icon>
              </div>
            </div>
            <div class="row" *ngIf="firmwareUpgrade?.pendingVersionMatrixId">
              <div class="key">{{ 'configurations.firmware.targetVersionMatrix' | translate }}</div>
              <div class="value">{{ firmwareUpgrade?.pendingVersionMatrixId }}</div>
            </div>
            <div
              class="row"
              *ngIf="!firmwareUpgrade?.pendingVersionMatrixId && !firmwareUpgrade?.activeVersionMatrixId"
            >
              <div class="key">{{ 'configurations.firmware.targetVersionMatrix' | translate }}</div>
              <div class="value">{{ 'configurations.firmware.noTargetVersionMatrix' | translate }}</div>
            </div>
            <div
              class="row"
              *ngIf="firmwareUpgrade?.updatedAt && firmwareUpgrade?.updatedAt != '1970-01-01T00:00:00.000Z'"
            >
              <div class="key">{{ 'configurations.firmware.updated' | translate }}</div>
              <div class="value">{{ firmwareUpgrade?.updatedAt | date : 'LLL' }}</div>
            </div>
            <button class="action medium" (click)="openModal('versionMatrix')" *ngIf="canUpgradeFirmware">
              {{ 'configurations.firmware.selectVersionMatrix' | translate }}
            </button>
          </div>
          <div class="column fixed">
            <button class="action medium" (click)="showMinimalVersion = true">
              {{ 'configurations.firmware.minimumVersions' | translate }}
            </button>
            <button class="action medium" (click)="navigateTimelines()">
              {{ 'configurations.firmware.upgradeFtm' | translate }}
            </button>
          </div>
        </div>
        <div class="details" *ngIf="details">
          <div class="table">
            <div class="status">
              <div class="column">
                <div class="row">
                  <div class="key">{{ 'configurations.firmware.target' | translate }}</div>
                  <div class="value">{{ details.target }}</div>
                </div>
                <div class="row">
                  <div class="key">{{ 'configurations.firmware.createdAt' | translate }}</div>
                  <div class="value">{{ details.created | date : 'LLL' }}</div>
                </div>
              </div>
              <div class="column">
                <div class="row">
                  <div class="key">{{ 'configurations.firmware.status' | translate }}</div>
                  <div class="value status">
                    <icon name="icon-spinner" class="spinner loading" *ngIf="inProgress"></icon>
                    {{ details.status }}
                  </div>
                </div>
                <div class="row">
                  <div class="key">{{ 'configurations.firmware.updatedAt' | translate }}</div>
                  <div class="value">{{ details.updated | date : 'LLL' }}</div>
                </div>
              </div>
            </div>
            <div class="headers">
              <div class="node">{{ 'configurations.firmware.nodeId' | translate }}</div>
              <div class="original">{{ 'configurations.firmware.originalFirmware' | translate }}</div>
              <div class="final">{{ 'configurations.firmware.updatedTo' | translate }}</div>
            </div>
            <div class="data" *ngFor="let node of details.nodes">
              <div class="node">{{ node.id }}</div>
              <div class="original">
                <div class="firmware">
                  {{
                    node.initFirmware.length ? node.initFirmware : ('configurations.firmware.noFirmwareSet' | translate)
                  }}
                </div>
                <div class="state">{{ node.initState }}</div>
              </div>
              <div class="final">
                <div class="firmware" *ngIf="!inProgress">
                  {{
                    node.finalFirmware.length ? node.finalFirmware : ('configurations.firmware.noChange' | translate)
                  }}
                </div>
                <div class="state">
                  <icon name="icon-spinner" class="size-0_7 spinner loading" *ngIf="inProgress"></icon>
                  {{ node.finalState }}
                  <icon
                    name="icon-info"
                    class="size-0_7"
                    [tooltip]="node.errorDescription"
                    *ngIf="node.finalState === 'failed'"
                  ></icon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </collapsiblebox>
  </div>
</div>

<modal mode="compact" (overlayAction)="showMinimalVersion = $event" *ngIf="showMinimalVersion">
  <ng-container title>{{ 'configurations.firmware.minimumVersions' | translate }}</ng-container>
  <ng-container content>
    <div class="row" *ngFor="let version of minimumVersions">
      <div class="key">{{ version.model }}</div>
      <div class="value">{{ version.version }}</div>
    </div>
  </ng-container>
  <ng-container controls>
    <button class="tertiary light" (click)="showMinimalVersion = false">
      {{ 'configurations.firmware.close' | translate }}
    </button>
  </ng-container>
</modal>

<modal (overlayAction)="showMatrixDetails = $event" *ngIf="showMatrixDetails">
  <ng-container title>{{ 'configurations.firmware.activeVersionMatrixDetails' | translate }}</ng-container>
  <ng-container content>
    <div class="row name">
      <div class="key">{{ 'configurations.firmware.activeMatrix' | translate }}</div>
      <div class="value">{{ matrixDetails.versionMatrix }}</div>
    </div>

    <div class="row name" *ngIf="matrixDetails.versionMatrixDescription">
      <div class="key">{{ 'configurations.firmware.activeVersionMatrixDescription' | translate }}</div>
      <div class="value">{{ matrixDetails.versionMatrixDescription }}</div>
    </div>

    <div class="row date">
      <div class="key">{{ 'configurations.firmware.createdAt' | translate }}</div>
      <div class="value">{{ matrixDetails.createdAt | date : 'LLL' }}</div>
    </div>
    <div class="list">
      <div class="model" *ngFor="let model of matrixDetails.models">
        <div class="info">
          <div class="row type">
            <div class="key">{{ 'configurations.firmware.model' | translate }}</div>
            <div class="value">{{ model.model }}</div>
          </div>
          <div class="row version">
            <div class="key">{{ 'configurations.firmware.version' | translate }}</div>
            <div class="value">{{ model.firmwareVersion }}</div>
          </div>
          <icon
            name="icon-arrow-down"
            class="size-0_8"
            [class.rotate180]="model.expand"
            (click)="model.expand = !model.expand"
          ></icon>
        </div>
        <div class="details" [class.expand]="model.expand">
          <div class="row">
            <div class="key">{{ 'configurations.firmware.residentialGateway' | translate }}</div>
            <div class="value">{{ model.residentialGateway }}</div>
          </div>
          <div class="row">
            <div class="key">{{ 'configurations.firmware.downloadUrl' | translate }}</div>
            <div class="value">{{ model.downloadUrl }}</div>
          </div>
          <div class="row">
            <div class="key">{{ 'configurations.firmware.encryptedUrl' | translate }}</div>
            <div class="value">{{ model.encryptedDownloadUrl }}</div>
          </div>
          <div class="row" *ngIf="model.modules">
            <div class="key">{{ 'configurations.firmware.modules' | translate }}</div>
            <div class="value">
              <div class="module header">
                <div class="name">{{ 'configurations.firmware.name' | translate }}</div>
                <div class="version">{{ 'configurations.firmware.version' | translate }}</div>
                <div class="filename">{{ 'configurations.firmware.filename' | translate }}</div>
              </div>
              <div class="module" *ngFor="let module of model.modules">
                <div class="name">{{ module.name }}</div>
                <div class="version">{{ module.version }}</div>
                <div class="filename">{{ module.filename }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container controls>
    <button class="tertiary light" (click)="showMatrixDetails = false">
      {{ 'configurations.firmware.close' | translate }}
    </button>
  </ng-container>
</modal>

<version-matrix-modal
  (closeVersionMatrixModal)="closeModal('versionMatrix')"
  (requestUpdate)="requestUpdate($event)"
  (clear)="clear($event)"
  (disable)="disable($event)"
  [allMatrices]="allMatrices"
  [locationModels]="locationModels"
  [matrixDetails]="matrixDetails"
  [singleLocation]="true"
  *ngIf="showMatrixSelect"
></version-matrix-modal>
