<div class="container-fluid" *ngIf="(customerLoading$ | async) === false" [class.open]="open$ | async">
  <ng-container *ngIf="optimisticLocation$ | async as location">
    <div class="lip" *ngrxLet="open$ as open" (click)="openPanel(!open)">
      <div class="label">{{ 'customerinfo.title' | translate }}</div>
      <icon name="icon-arrow-down" class="size-0_6" [class.rotate180]="!open"></icon>
    </div>
    <div cdk-scrollable class="content">
      <locationproducts
        [locationId]="location.id"
        [partnerId]="location.partnerId"
        [isUprise]="location.uprise"
      ></locationproducts>

      <div class="key">{{ 'customerinfo.name' | translate }}</div>
      <div class="value">{{ (customer$ | async)?.name | customerName }}</div>

      <ng-container *ngIf="!isFlexAdmin">
        <div class="key">
          {{ 'customerinfo.account' | translate }}
          <drop-down-icon class="medium" iconSize="size-0_8">
            <li (click)="openCustomerGroupsModal()">
              {{ 'customerinfo.showCustomerGroups' | translate }}
            </li>
            <li
              *ngIf="permissions?.uiFeatures.editEmailVerified && (customer$ | async)?.emailVerified"
              (click)="changeEmailVerified()"
            >
              {{ 'customerinfo.changeNotVerified' | translate }}
            </li>
            <li
              *ngIf="permissions?.uiFeatures.editEmailVerified && !(customer$ | async)?.emailVerified"
              (click)="changeEmailVerified()"
            >
              {{ 'customerinfo.changeToVerified' | translate }}
            </li>
            <li
              *ngIf="permissions?.uiFeatures.sendPasswordReset && (customer$ | async)?.emailVerified"
              (click)="sendPasswordReset()"
            >
              {{ 'customerinfo.sandPassword' | translate }}
            </li>
            <li
              *ngIf="permissions?.uiFeatures.resendEmailVerified && !(customer$ | async)?.emailVerified"
              (click)="resendEmailVerified()"
            >
              {{ 'customerinfo.sendVerification' | translate }}
            </li>
            <div class="customerDropDownInfo">{{ (customer$ | async)?.email | email }}</div>
          </drop-down-icon>
        </div>
        <div class="value">
          <div [tooltip]="(customer$ | async)?.email | email" [onlyOverflowText]="true" class="ellipsis">
            {{ (customer$ | async)?.email | email }}
          </div>
          <icon
            name="icon-check"
            class="size-0_8 no-click"
            tooltip="{{ 'customerinfo.emailVerified' | translate }}"
            *ngIf="(customer$ | async)?.emailVerified"
          ></icon>
        </div>
        <div class="key">
          {{ 'customerinfo.location' | translate }}
          <icon
            name="icon-location-pin"
            class="size-0_8"
            (click)="drawMap(geoIp)"
            *ngIf="(optimisticLocation$ | async)?.geoIp as geoIp"
          ></icon>
        </div>
        <div class="value">
          {{ (optimisticLocation$ | async)?.geoIp?.city || ('customerinfo.na' | translate) }},
          {{ (optimisticLocation$ | async)?.geoIp?.country || ('customerinfo.na' | translate) }}
          <icon
            name="icon-info"
            class="size-0_8 no-click"
            tooltip="{{ 'customerinfo.locationTooltip' | translate }}"
          ></icon>
        </div>

        <div class="key">{{ 'customerinfo.region' | translate }}</div>
        <div class="value">
          {{ location.locale?.region || ('customerinfo.na' | translate) }}
          <icon
            name="icon-info"
            class="size-0_8 no-click"
            tooltip="{{ 'customerinfo.regionTooltip' | translate }}"
          ></icon>
        </div>

        <div class="key">
          {{ 'customerinfo.accountid' | translate }}
          <icon
            name="icon-edit"
            class="size-0_8"
            (click)="enableRename()"
            *ngIf="!editAccountId && permissions?.uiFeatures.editAccountId && !isUprise"
          ></icon>
          <div class="controls" *ngIf="editAccountId">
            <icon
              name="icon-check"
              class="size-0_8 confirm"
              [class.disabled]="!accountId.value"
              (click)="confirmAccountIdRename()"
            ></icon>
            <icon name="icon-close" class="size-0_6 cancel" (click)="cancelRename()"></icon>
          </div>
        </div>
        <div class="value edit">
          <div
            class="editable accountId ellipsis"
            [class.hide]="editAccountId"
            [tooltip]="(customer$ | async)?.accountId"
            [onlyOverflowText]="true"
          >
            {{ (customer$ | async)?.accountId }}
          </div>
          <div class="editable accountId" [class.hide]="!editAccountId">
            <input
              (keyup.enter)="confirmAccountIdRename()"
              (keyup.escape)="cancelRename()"
              [formControl]="accountId"
              type="text"
              placeholder="{{ 'customerinfo.accountid' | translate }}..."
              autofocus
            />
          </div>
          <div class="input-underline" [class.show]="editAccountId"></div>
          <div *ngIf="editAccountId && !accountId.value" class="error-message">
            {{ 'techdash.infoArea.accountIdValidation' | translate }}
          </div>
        </div>
      </ng-container>

      <div class="key">{{ 'customerinfo.customerid' | translate }}</div>
      <div class="value">{{ (customer$ | async)?.id }}</div>

      <div class="key">
        {{ 'customerinfo.locationid' | translate }}
        <drop-down-icon class="medium" iconSize="size-0_8" *ngIf="!isFlexAdmin">
          <li (click)="showLocationConfigurationScreen()">
            {{ 'customerinfo.showLocationConfiguration' | translate }}
          </li>
          <li (click)="openNeighborsReportsModal()" *ngIf="permissions?.uiFeatures?.neighborsHealthCheck">
            {{ 'customerinfo.showNeighborsReport' | translate }}
          </li>
          <li (click)="showLocationAuthorizationScreen()" *ngIf="location.authorizations">
            {{ 'customerinfo.showLocationAuthorization' | translate }}
          </li>
          <li (click)="showLogPullScreen()" *ngIf="!plume.isUpriseProperyManager()">
            {{ 'customerinfo.performLogpull' | translate }}
          </li>
          <li (click)="showLogPullHistoryScreen()" *ngIf="!plume.isUpriseProperyManager()">
            {{ 'customerinfo.showLogpullHistory' | translate }}
          </li>
          <li (click)="newLocationScreen()" *ngIf="permissions?.uiFeatures?.addNewLocation">
            {{ 'customerinfo.addNewLocation' | translate }}
          </li>
          <li
            (click)="archiveLocation()"
            [class.disable]="isUprise"
            *ngIf="(locationList$ | async)?.length > 1 && permissions?.uiFeatures?.archiveLocation"
          >
            {{ 'customerinfo.archiveLocation' | translate }}
          </li>
        </drop-down-icon>
      </div>
      <div class="value">
        <span>{{ location.id }}</span>
        <icon
          name="icon-info"
          class="size-0_8 no-click"
          tooltip="
      {{ 'customerinfo.createdAt' | translate }}: {{ formatDate(location?.createdAt) | translate }}<br>
      {{ 'customerinfo.onboardedAt' | translate }}: {{ formatDate(location?.onboardedAt) | translate }}"
        ></icon>
      </div>

      <ng-container *ngIf="!isFlexAdmin">
        <div class="key">{{ 'customerinfo.isp' | translate }}</div>
        <div class="value">
          {{ location.geoIp?.ISP || ('customerinfo.na' | translate) }}
          <icon name="icon-info" class="size-0_8 no-click" tooltip="{{ 'customerinfo.ispTooltip' | translate }}"></icon>
        </div>

        <div class="key">
          {{ 'customerinfo.networkMode' | translate }}
          <drop-down-icon
            class="medium"
            iconSize="size-0_8"
            *ngIf="(networkModeEditable$ | async) && (capabilities$ | async)?.networkMode?.capable"
          >
            <li *ngIf="location.networkMode != 'auto'" (click)="changeNetworkMode('auto')">
              {{ 'customerinfo.changeToAuto' | translate }}
            </li>
            <li *ngIf="location.networkMode != 'bridge'" (click)="changeNetworkMode('bridge')">
              {{ 'customerinfo.changeToBridge' | translate }}
            </li>
            <li *ngIf="location.networkMode != 'router'" (click)="changeNetworkMode('router')">
              {{ 'customerinfo.changeToRouter' | translate }}
            </li>
            <div class="customerDropDownInfo">
              {{ 'customerinfo.currentNetworkMode' | translate }}:
              {{ (location.networkMode | translate) || ('customerinfo.na' | translate) }}
            </div>
          </drop-down-icon>
        </div>
        <div class="value">
          {{ (location.networkModeRealized | translate) || ('customerinfo.na' | translate) }} ({{
            (location.networkMode | translate) || ('customerinfo.na' | translate)
          }})
        </div>

        <div class="key">{{ 'customerinfo.networkBusy' | translate }}</div>
        <div class="value">
          {{ (location.busy?.toString() | translate) || ('customerinfo.na' | translate) }}
        </div>

        <ng-container *ngrxLet="membership$ as membership">
          <div class="key" *ngIf="membership?.status && membership?.status != 'grandfathered'">
            {{ 'customerinfo.membershipLabel' | translate }}
          </div>
          <div class="value" *ngIf="membership?.status && membership?.status != 'grandfathered'">
            {{ membership?.status | translate }}
            <icon
              name="icon-info"
              class="size-0_8 no-click"
              tooltip="
      {{ 'customerinfo.membership.status' | translate }}: {{ membership?.status | translate }}<br>
      {{ 'customerinfo.membership.effectiveAt' | translate }}: {{
                toLocalDate(membership?.effectiveAt | translate) || 'N/A'
              }}<br>
      {{ 'customerinfo.membership.term' | translate }}: {{ (membership?.term?.toString() | translate) || 'N/A' }}<br>
      {{ 'customerinfo.membership.expiresAt' | translate }}: {{
                toLocalDate(membership?.expiresAt | translate) || 'N/A'
              }}<br>"
            ></icon>
          </div>
        </ng-container>

        <div class="networkId">
          <div class="separator"></div>
          <collapsiblebox
            mode="bare"
            toggleSide="right"
            [showDivider]="false"
            [show]="networkIdOpened"
            [collapsible]="true"
            (toggle)="networkIdOpened = !networkIdOpened"
          >
            <ng-container header>
              <div class="title">{{ 'customerinfo.networkId' | translate }}</div>
            </ng-container>
          </collapsiblebox>
          <div class="boxBody" *ngIf="networkIdOpened">
            <ng-container *ngIf="!location.partnerName">
              <div class="networkIdRow" *ngFor="let group of groups">
                <div class="name">{{ group.name }}</div>
                <div class="action" (click)="openCustomerGroupsModal()">{{ 'customerinfo.info' | translate }}</div>
              </div>
              <div class="networkIdRow" *ngIf="groups?.length === 0">
                <div class="name">{{ 'customerinfo.noPartnerInformation' | translate }}</div>
              </div>
            </ng-container>
            <div class="networkIdRow" *ngIf="location.partnerName">
              <div class="name">{{ location.partnerName }}</div>
              <div class="action" (click)="openCustomerGroupsModal()">{{ 'customerinfo.info' | translate }}</div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>

<modal mode="compact" (overlayAction)="showMap = $event" *ngIf="showMap">
  <ng-container content>
    <div class="map" id="map"></div>
  </ng-container>
  <ng-container controls>
    <button class="tertiary light" (click)="showMap = false">
      {{ 'customerinfo.map.close' | translate }}
    </button>
  </ng-container>
</modal>

<modal
  mode="wide"
  (overlayAction)="showLocationConfiguration = $event"
  *ngIf="showLocationConfiguration && optimisticLocation$ | async as location"
>
  <ng-container title>{{ 'customerinfo.locationConfiguration.title' | translate }}</ng-container>
  <ng-container content>
    <div class="grid">
      <div class="label">{{ 'customerinfo.locationConfiguration.locationId' | translate }}:</div>
      <div class="value">{{ location.id }}</div>
    </div>
    <div class="grid">
      <div class="label">{{ 'customerinfo.locationConfiguration.locationName' | translate }}:</div>
      <div class="value locationName" *ngIf="!editLocationName">
        <div class="name">{{ location.name }}</div>
        <icon name="icon-edit" (click)="enableLocationName(location.name)" *ngIf="plume.isSomeAdminRole()"></icon>
      </div>
      <div class="value locationName" *ngIf="editLocationName">
        <input
          (keyup.enter)="confirmLocationName()"
          (keyup.escape)="cancelLocationName()"
          [formControl]="locationName"
          type="text"
          placeholder="{{ 'customerinfo.locationConfiguration.locationNamePlaceholder' | translate }}..."
          autofocus
        />
        <icon name="icon-check" class="confirm" (click)="confirmLocationName()"></icon>
        <icon name="icon-close" class="size-0_8 cancel" (click)="cancelLocationName()"></icon>
      </div>
    </div>
    <div class="grid">
      <div class="label">{{ 'customerinfo.locationConfiguration.serviceId' | translate }}:</div>
      <div class="value serviceId" *ngIf="!editServiceId">
        {{ location.serviceId }}
        <icon name="icon-edit" (click)="enableServiceId(location.serviceId)" *ngIf="plume.isSomeAdminRole()"></icon>
      </div>
      <div class="value serviceId" *ngIf="editServiceId">
        <input
          (keyup.enter)="confirmServiceId()"
          (keyup.escape)="cancelServiceId()"
          [formControl]="serviceId"
          type="text"
          placeholder="{{ 'customerinfo.locationConfiguration.newServiceId' | translate }}..."
          autofocus
        />
        <icon name="icon-check" class="confirm" (click)="confirmServiceId()"></icon>
        <icon name="icon-close" class="size-0_8 cancel" (click)="cancelServiceId()"></icon>
      </div>
    </div>
    <div class="grid">
      <div class="label">{{ 'customerinfo.locationConfiguration.autoOptimize' | translate }}:</div>
      <div class="value">
        <slider-toggle-mobile-aware
          [options]="autoOptimizeItems$ | async"
          [editable]="configurationEditable"
          (toggle)="action('autoOptimize', $event)"
        ></slider-toggle-mobile-aware>
      </div>
    </div>
    <div class="grid">
      <div class="label">{{ 'customerinfo.locationConfiguration.dfsMode' | translate }}:</div>
      <div class="value">
        <slider-toggle-mobile-aware
          [options]="dfsModeItems$ | async"
          [editable]="configurationEditable"
          (toggle)="action('dfsMode', $event)"
        ></slider-toggle-mobile-aware>
      </div>
    </div>
    <div class="grid">
      <div class="label" tooltip="{{ 'customerinfo.locationConfiguration.bandSteering' | translate }}">
        {{ 'customerinfo.locationConfiguration.opensyncSteering' | translate }}:
      </div>
      <div class="value">
        <slider-toggle-mobile-aware
          [options]="bandSteeringItems$ | async"
          [editable]="selectBandSteeringEditable$ | async"
          (toggle)="action('bandSteering', $event)"
        ></slider-toggle-mobile-aware>
      </div>
    </div>
    <div class="grid">
      <div class="label" tooltip="{{ 'customerinfo.locationConfiguration.clientSteering' | translate }}">
        {{ 'customerinfo.locationConfiguration.cloudSteering' | translate }}:
      </div>
      <div class="value">
        <slider-toggle-mobile-aware
          [options]="clientSteeringItems$ | async"
          [editable]="false"
          (toggle)="action('clientSteering', $event)"
        ></slider-toggle-mobile-aware>
      </div>
    </div>
    <div class="grid">
      <div class="label">{{ 'customerinfo.locationConfiguration.serviceLevel' | translate }}:</div>
      <div class="value">
        <slider-toggle-mobile-aware
          [options]="serviceLevelItems$ | async"
          [editable]="plume.isSomeAdminRole() && (membership$ | async)?.ratePlans?.length === 0"
          (toggle)="action('serviceLevel', $event)"
        ></slider-toggle-mobile-aware>
      </div>
    </div>
    <div class="grid">
      <div class="label">{{ 'configurations.wifiradio.controlMode' | translate }}:</div>
      <div class="value">
        <slider-toggle-mobile-aware
          [options]="controlModeItems$ | async"
          [editable]="(plume.isSomeAdminRole() || plume.isStrictGroupSupportRole()) && (nonFullModeCapable$ | async)"
          (toggle)="action('controlMode', $event)"
        ></slider-toggle-mobile-aware>
      </div>
    </div>
    <div class="grid">
      <div class="label">{{ 'customerinfo.locationConfiguration.wifiRadios' | translate }}:</div>
      <div class="value">
        <slider-toggle-mobile-aware
          [options]="wifiRadioItems$ | async"
          [editable]="plume.isSomeAdminRole() && (capabilities$ | async)?.radioDisableSupported?.capable"
          (toggle)="action('wifiRadios', $event)"
        ></slider-toggle-mobile-aware>
        <icon
          name="icon-info"
          class="no-click infoIcon"
          [tooltip]="'configurations.wifiradio.mayPreventFrontLine' | translate"
        ></icon>
      </div>
    </div>
  </ng-container>
  <ng-container controls>
    <button class="tertiary light" (click)="closeLocationConfiguration()">
      {{ 'customerinfo.locationConfiguration.close' | translate }}
    </button>
  </ng-container>
</modal>

<neighborsreport [triggerNeighborsReports]="showNeighborsReportsModal"></neighborsreport>

<logpullmodal [(showLogPull)]="showLogPull"></logpullmodal>

<modal (overlayAction)="showLogPullHistory = $event" *ngIf="showLogPullHistory">
  <ng-container title>{{ 'customerinfo.logpullHistory.title' | translate }}</ng-container>
  <ng-container content>
    <div class="label">{{ 'customerinfo.logpullHistory.message' | translate }}</div>
    <div class="events">
      <h3>{{ 'customerinfo.logpullHistory.historyList' | translate }}</h3>
      <ng-container *ngIf="!logpullHistory">
        <div class="loading">{{ 'loadingIndicator' | translate }}</div>
      </ng-container>
      <ng-container *ngIf="logpullHistory">
        <div class="header">
          <div class="date">{{ 'customerinfo.logpullHistory.datetime' | translate }}</div>
          <div class="comment">{{ 'customerinfo.logpullHistory.comment' | translate }}</div>
          <div class="download" *ngIf="permissions?.uiFeatures.downloadLogpull"></div>
          <div class="copyLink"></div>
        </div>
        <div class="eventsList">
          <div class="event" *ngFor="let event of logpullHistory">
            <div class="date">{{ event.createdAt }}</div>
            <div class="comment">{{ event.comment }}</div>
            <div class="download" *ngIf="permissions?.uiFeatures.downloadLogpull">
              <div class="controls">
                <button class="action small" (click)="downloadLogpull(event.link)">
                  {{ 'customerinfo.logpullHistory.download' | translate }}
                </button>
              </div>
            </div>
            <div class="copyLink">
              <div class="controls">
                <button class="action small" (click)="copied(event.copyLink)">
                  {{ 'customerinfo.logpullHistory.copyLink' | translate }}
                </button>
              </div>
            </div>
          </div>
          <div class="event" *ngIf="!logpullHistory.length">
            <div class="noEvents">{{ 'customerinfo.logpullHistory.noHistory' | translate }}</div>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
  <ng-container controls>
    <button class="tertiary light" (click)="showLogPullHistory = false">
      {{ 'customerinfo.logpullHistory.close' | translate }}
    </button>
  </ng-container>
</modal>

<modal mode="compact" (overlayAction)="showLocationAuthorization = $event" *ngIf="showLocationAuthorization">
  <ng-container title>{{ 'customerinfo.locationAuthorization.title' | translate }}</ng-container>
  <ng-container content>
    <locationauthorization></locationauthorization>
  </ng-container>
  <ng-container controls>
    <button class="tertiary light" (click)="showLocationAuthorization = false">
      {{ 'customerinfo.locationAuthorization.close' | translate }}
    </button>
  </ng-container>
</modal>

<modal (overlayAction)="showCustomerGroupsModal = $event" *ngIf="showCustomerGroupsModal">
  <ng-container title>{{ 'customerGroups.title' | translate }}</ng-container>
  <ng-container content>
    <customergroups (groupsChanged)="groups = $event"></customergroups>
  </ng-container>
  <ng-container controls>
    <button class="tertiary light" (click)="showCustomerGroupsModal = false">
      {{ 'customerGroups.close' | translate }}
    </button>
  </ng-container>
</modal>

<modal mode="compact" (overlayAction)="showNewLocation = $event" *ngIf="showNewLocation">
  <ng-container title>{{ 'customerinfo.newLocation.title' | translate }}</ng-container>
  <ng-container content>
    <div class="label">
      {{ 'customerinfo.newLocation.description' | translate }}
    </div>
    <div class="new-location">
      <input
        [formControl]="newLocation"
        type="text"
        placeholder="{{ 'customerinfo.newLocation.placeholder' | translate }}"
        autofocus
      />
      <div class="validation" *ngIf="!newLocationValidation">
        {{ 'customerinfo.newLocation.invalidNewName' | translate }}
      </div>
    </div>
    <div class="new-profile">
      <div class="profile-tag">
        {{ 'customerinfo.newLocation.locationProfile' | translate }}
      </div>
      <slidetoggler [options]="profileModeItems" (toggle)="profileMode = $event"></slidetoggler>
    </div>
  </ng-container>
  <ng-container controls>
    <button class="tertiary light" (click)="closeNewLocation()">
      {{ 'customerinfo.newLocation.close' | translate }}
    </button>
    <button class="super-primary" (click)="addNewLocation()">
      {{ 'customerinfo.newLocation.add' | translate }}
    </button>
  </ng-container>
</modal>
