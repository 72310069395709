<modal mode="wide" class="version-select" (overlayAction)="closeModal()">
  <ng-container title>{{
    (singleLocation ? 'configurations.firmware.selectMatrix' : 'netops.firmware.lookupFirmware') | translate
  }}</ng-container>
  <ng-container content>
    <div class="row withGap">
      <ng-container *ngIf="singleLocation">
        <div>{{ 'configurations.firmware.filterRelevantToThisLocation' | translate }}</div>
        <div class="toggler" (click)="filterLocationSpecificVmToggle()">
          <div class="checkbox">
            <label for="toggleChannel" class="toggle" [class.checked]="filterLocationSpecificVm"></label>
          </div>
        </div>
      </ng-container>
      <div>{{ 'configurations.firmware.filterOnboardingOnly' | translate }}</div>
      <div class="toggler" (click)="filterOnboardingVmToggle()">
        <div class="checkbox">
          <label for="toggleChannel" class="toggle" [class.checked]="filterOnboardingVm"></label>
        </div>
      </div>
    </div>
    <ng-container *ngIf="matrices.length === 0 && !search.value">
      {{ 'configurations.firmware.noApplicableFirmware' | translate }}
    </ng-container>
    <ng-container *ngIf="matrices.length > 0 || search.value?.length > 0">
      <div class="top">
        <div class="left">
          <ng-container *ngIf="matrixDetails && singleLocation">
            <div class="row name">
              <div class="key">{{ 'configurations.firmware.activeMatrix' | translate }}</div>
              <div class="value">{{ matrixDetails.versionMatrix }}</div>
            </div>

            <div class="row name" *ngIf="matrixDetails.versionMatrixDescription">
              <div class="key">{{ 'configurations.firmware.activeVersionMatrixDescription' | translate }}</div>
              <div class="value">{{ matrixDetails.versionMatrixDescription }}</div>
            </div>

            <div class="row date">
              <div class="key">{{ 'configurations.firmware.createdAt' | translate }}</div>
              <div class="value">{{ matrixDetails.createdAt | date : 'LLL' }}</div>
            </div>
          </ng-container>
        </div>
        <div class="right">
          <slidetoggler [options]="downgradeItems" (toggle)="toggleDowngrade($event)"></slidetoggler>
          <div class="actions" *ngIf="matrixDetails || !singleLocation">
            <button class="action medium" (click)="clear.emit(downgrade)">
              {{ 'configurations.firmware.clearTarget' | translate }}
            </button>
            <button class="action medium" (click)="disable.emit(downgrade)">
              {{ 'configurations.firmware.disableActiveMatrix' | translate }}
            </button>
          </div>
        </div>
      </div>
      <div class="sections">
        <div class="section">
          <div class="search title">
            <span>
              {{ 'configurations.firmware.searchVersions' | translate }}
            </span>
            <span>
              {{ 'configurations.firmware.includeModelVersion' | translate }}
              <div class="toggler" (click)="filterIncludeModelsToggle()">
                <div class="checkbox">
                  <label class="toggle" [class.checked]="filterIncludeModels"></label>
                </div>
              </div>
            </span>
          </div>
          <div class="search">
            <input
              type="text"
              placeholder="{{ 'configurations.firmware.versionsPlaceholder' | translate }}"
              (input)="filterSearch()"
              [formControl]="search"
            />
          </div>
          <div class="list">
            <div
              class="version"
              [class.selected]="selectedVersion && version.versionMatrix === selectedVersion.versionMatrix"
              *ngFor="let version of matrices; trackBy: matricesTrack"
              (mouseenter)="versionDetails = version"
              (mouseleave)="versionDetails = selectedVersion ? selectedVersion : null"
              (click)="selectThisVersion(version)"
            >
              {{ version.versionMatrix }}
            </div>
          </div>
        </div>
        <div class="section">
          <div class="list" *ngIf="versionDetails">
            <div class="row date">
              <div class="key">{{ 'configurations.firmware.createdAt' | translate }}</div>
              <div class="value">{{ versionDetails.createdAt | date : 'LLL' }}</div>
            </div>
            <div class="row description" *ngIf="versionDetails.versionMatrixDescription">
              <div class="key">{{ 'configurations.firmware.activeVersionMatrixDescription' | translate }}</div>
              <div class="value">{{ versionDetails.versionMatrixDescription }}</div>
            </div>
            <div class="model" *ngFor="let model of versionDetails.models">
              <div class="row">
                <div class="key">{{ 'configurations.firmware.model' | translate }}</div>
                <div class="value">{{ model.model }}</div>
              </div>
              <div class="row">
                <div class="key">{{ 'configurations.firmware.version' | translate }}</div>
                <div class="value">{{ model.firmwareVersion }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <ng-container controls>
    <div class="desktop">
      <button class="tertiary light" (click)="closeModal()">
        {{ 'configurations.firmware.close' | translate }}
      </button>
      <div class="update" *ngIf="selectedVersion">
        <slidetoggler [options]="asapItems" (toggle)="toggleAsap($event)"></slidetoggler>
        <button class="super-primary" (click)="createUpgrade()">
          {{ (singleLocation ? 'configurations.firmware.requestUpdate' : 'netops.firmware.select') | translate }}
        </button>
      </div>
    </div>
    <button class="super-primary mobile" (click)="createUpgrade()" *ngIf="selectedVersion">
      {{ (singleLocation ? 'configurations.firmware.requestUpdate' : 'netops.firmware.select') | translate }}
    </button>
  </ng-container>
</modal>
