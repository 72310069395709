import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment/moment';
import { Subject, forkJoin } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { INode } from 'src/app/lib/interfaces/interface';
import { ApiService } from 'src/app/lib/services/api.service';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { selectNodes } from 'src/app/store/polling/polling.selector';

@Component({
  selector: 'optimizertimeline',
  templateUrl: './optimizertimeline.component.html',
  styleUrls: ['./optimizertimeline.component.scss']
})
export class OptimizerTimelineComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  active: boolean;

  nodesSubscription: any;
  nodes: INode[] = [];
  expanded: boolean = false;
  initEvent: boolean = true;
  allEvents: any[];
  optimizerEvents: any[];
  dayView: any = null;
  eventDetails: any = null;
  maxEvents: number = 500;
  batchSize: number = 100;
  moreThanMaxEvents: boolean = false;
  moreThanMaxRequests: boolean = false;
  daysOfData: number = 28;
  numRequests: number = 0;
  maxRequests: number = 5;
  nodeTimeout: any;
  oldSubscriptionCleaner = new Subject<void>();

  selector: any = {
    current: 'timelines.optimizer.filter.allExceptPowerAwareness',
    filter: 'noPowerAwareness'
  };

  legend: any[] = [
    { text: 'timelines.optimizer.legend.succeeded', color: 'green' },
    { text: 'timelines.optimizer.legend.failed', color: 'red' },
    { text: 'timelines.optimizer.legend.unknown', color: 'yellow' }
  ];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private api: ApiService,
    private plume: PlumeService,
    private mixpanel: MixpanelService,
    private translate: TranslateService,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.nodesSubscription = this.store
      .select(selectNodes)
      .pipe(filter((nodes) => !!nodes))
      .subscribe((nodes) => {
        this.nodes = nodes;
        if (this.initEvent && this.expanded) {
          this.initData();
        }
      });
  }

  ngOnChanges(changes: any): void {
    if (changes.active && changes.active.currentValue) {
      this.expanded = true;
      this.timeout();
      this.initData();
    } else {
      this.expanded = false;
      this.initEvent = true;
      this.reset();
    }
  }

  timeout(): void {
    clearTimeout(this.nodeTimeout);
    this.nodeTimeout = setTimeout(() => {
      this.getOptimizerData();
      this.initEvent = false;
    }, 3000);
  }

  initData(): void {
    this.reset();

    if (this.nodes?.length) {
      clearTimeout(this.nodeTimeout);

      this.initEvent = false;

      if (this.nodes.length >= 10) {
        this.batchSize = 5;
        this.maxRequests = 20;
        this.maxEvents = 100;
      }

      this.getOptimizerData();
    }
  }

  reset(): void {
    this.allEvents = null;
    this.optimizerEvents = null;
    this.dayView = null;
    this.eventDetails = null;
    this.moreThanMaxEvents = false;
    this.moreThanMaxRequests = false;
    this.numRequests = 0;

    this.selector = {
      current: 'timelines.optimizer.filter.allExceptPowerAwareness',
      filter: 'noPowerAwareness'
    };

    this.oldSubscriptionCleaner.next();
  }

  toggleExpand(): void {
    const enabledModes = this.route.snapshot.params?.mode?.length ? this.route.snapshot.params.mode.split(',') : [];

    if (this.expanded) {
      this.expanded = false;
      this.dayView = null;
      this.eventDetails = null;
      this.mixpanel.storeEvent('TIMELINES_OPTIMIZER_EXPAND', { EXPANDED: false });
      this.router.navigate([
        'customer',
        this.plume.customerid,
        'location',
        this.plume.locationid,
        'timelines',
        enabledModes.filter((mode: string) => mode !== 'optimizer').join(',')
      ]);
    } else {
      this.expanded = true;
      this.mixpanel.storeEvent('TIMELINES_OPTIMIZER_EXPAND', { EXPANDED: true });
      this.router.navigate([
        'customer',
        this.plume.customerid,
        'location',
        this.plume.locationid,
        'timelines',
        [...enabledModes, 'optimizer'].join(',')
      ]);
    }
  }

  getOptimizerData(endAt: any = null): void {
    const url = '/Customers/' + this.plume.customerid + '/locations';
    const endpoint = 'reports';
    const endTime = endAt ? moment(endAt).utc() : moment().utc();
    const startTime = moment().startOf('day').subtract(this.daysOfData, 'days').utc();

    const requests = this.api.get(
      `${url}/${this.plume.locationid}/optimizeRequests?limit=${
        this.batchSize
      }&deep=true&startAt=${startTime.toISOString()}&endAt=${endTime.toISOString()}`,
      endpoint
    );

    const responses = this.api.get(
      `${url}/${this.plume.locationid}/optimizeResponses?limit=${
        this.batchSize
      }&deep=true&startAt=${startTime.toISOString()}&endAt=${endTime.toISOString()}`,
      endpoint
    );

    const topologyChanges = this.api.get(
      `${url}/${this.plume.locationid}/topologyChangeResults?limit=${
        this.batchSize
      }&deep=true&startAt=${startTime.toISOString()}&endAt=${endTime.toISOString()}`,
      endpoint
    );

    forkJoin({ requests, responses, topologyChanges })
      .pipe(takeUntil(this.oldSubscriptionCleaner))
      .subscribe((data: any) => {
        const events = [];

        data.requests = data.requests.map((request: string) => JSON.parse(request));
        data.responses = data.responses.map((response: string) => JSON.parse(response));
        data.topologyChanges = data.topologyChanges.map((topology: string) => JSON.parse(topology));
        data.responses.forEach((response: any) => {
          const request = data.requests.find((request: any) => request.id === response.id);
          const topologyChange = data.topologyChanges.find((topology: any) => topology.id === response.id);
          const timeOfDay = request && request.createdAt ? moment(request.createdAt).format('LT') : '';
          const triggers =
            request && request.triggers
              ? request.triggers
                  .map((trigger: string) =>
                    this.translate.instant('timelines.optimizer.details.triggersList.' + trigger)
                  )
                  .join(', ')
              : '';
          const tooltipProperties = [
            { key: 'timelines.optimizer.eventAt', value: timeOfDay },
            { key: 'timelines.optimizer.triggers', value: triggers },
            { key: 'timelines.optimizer.responseStatus', value: response.status },
            {
              key: 'timelines.optimizer.topologyChangeStatus',
              value: topologyChange ? topologyChange.status : 'unknown'
            }
          ];

          events.push({
            createdAt: request?.createdAt ?? response?.createdAt ?? topologyChange?.createdAt ?? '',
            eventData: {
              response,
              request,
              topologyChange,
              tooltipProperties,
              eventTime: timeOfDay,
              eventName: triggers
            },
            eventType: topologyChange
              ? topologyChange.status + ' ' + (request && request.triggers ? request.triggers.join(' ') : '')
              : 'unknown ' + (request && request.triggers ? request.triggers.join(' ') : '')
          });
        });
        this.numRequests += 1;

        this.allEvents = this.allEvents ? [...this.allEvents, ...events] : [...events];

        if (
          events.length > 1 &&
          events.length >= this.batchSize &&
          this.allEvents.length &&
          this.allEvents.length < this.maxEvents &&
          this.numRequests < this.maxRequests
        ) {
          const lastTime = moment(this.allEvents[this.allEvents.length - 1].createdAt).utc();

          if (startTime.valueOf() < lastTime.valueOf()) {
            const newDate = moment(this.allEvents[this.allEvents.length - 1].createdAt);
            this.getOptimizerData(newDate.valueOf() - 1);
            return;
          }
        }

        this.moreThanMaxEvents = this.allEvents.length >= this.maxEvents;
        this.moreThanMaxRequests = this.numRequests >= this.maxRequests;
        this.filter();

        if (this.optimizerEvents.length) {
          this.selectEvent(this.optimizerEvents[0]);
        }
      });
  }

  changeView(event: any): void {
    this.dayView = event;
  }

  selectEvent(event: any): void {
    this.eventDetails = { ...event };
    this.mixpanel.storeEvent('TIMELINES_OPTIMIZER_SELECT_EVENT', {
      OPTIMIZE_EVENT_REQUEST_ID: event?.eventData?.request?.id ?? event?.eventData?.response?.id
    });
  }

  filterEvents(translation: string, type: string): void {
    this.selector.current = translation;
    this.selector.filter = type;
    this.mixpanel.storeEvent('TIMELINES_OPTIMIZER_FILTER_EVENTS', { OPTIMIZE_FILTER: type });

    this.filter();
  }

  filter(): void {
    if (this.allEvents) {
      if (this.selector.filter === 'PowerAwareness') {
        this.optimizerEvents = this.allEvents.filter(
          (event: any) =>
            event.eventType.indexOf('EnterPowerMode') > -1 || event.eventType.indexOf('ExitPowerMode') > -1
        );
      } else if (this.selector.filter === 'noPowerAwareness') {
        this.optimizerEvents = this.allEvents.filter(
          (event: any) =>
            (event.eventType.indexOf('EnterPowerMode') === -1 && event.eventType.indexOf('ExitPowerMode') === -1) ||
            event.eventData?.request?.triggers?.length > 2
        );
      } else if (this.selector.filter === 'noFilter') {
        this.optimizerEvents = this.allEvents;
      } else {
        this.optimizerEvents = this.allEvents.filter(
          (event: any) => event.eventType.indexOf(this.selector.filter) > -1
        );
      }
    }
  }

  ngOnDestroy(): void {
    if (this.nodeTimeout) {
      clearTimeout(this.nodeTimeout);
    }

    if (this.nodesSubscription) {
      this.nodesSubscription.unsubscribe();
    }
  }
}
