import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Parser } from 'json2csv';
import * as moment from 'moment';
import { getDiff } from 'recursive-diff';
import { take } from 'rxjs/operators';
import { ChannelHelper } from 'src/app/lib/helpers/channel.helper';
import { GeneralHelper } from 'src/app/lib/helpers/general.helper';
import { IDevice } from 'src/app/lib/interfaces/interface';
import { CustomerService } from 'src/app/lib/services/customer.service';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { selectDevices, selectNodes } from 'src/app/store/polling/polling.selector';

@UntilDestroy()
@Component({
  selector: 'recenteventstimeline',
  templateUrl: './recenteventstimeline.component.html',
  styleUrls: ['./recenteventstimeline.component.scss']
})
export class RecentEventsTimelineComponent implements OnInit, OnChanges {
  @Input()
  active: boolean;

  expanded: boolean = false;
  loading: boolean = false;
  nodes: any[] = null;
  devices: IDevice[] = null;
  allEvents: any[] = [];
  allFilteredEvents: any[] = [];
  events: any[] = [];
  chart: any = {};
  selectedBucket: any = null;
  channelHelper: ChannelHelper = new ChannelHelper();
  helper: GeneralHelper = new GeneralHelper();
  filters: any = ['connectionState', 'parentId', 'channel', 'devices', 'ethernetdevices', 'optimization', 'powerMode'];
  filterItems: any = [
    { text: 'health.networkInformation.topologyHistory.connectionState', value: 'connectionState' },
    { text: 'health.networkInformation.topologyHistory.parentId', value: 'parentId' },
    { text: 'health.networkInformation.topologyHistory.channel', value: 'channel' },
    { text: 'health.networkInformation.topologyHistory.devices', value: 'devices' },
    { text: 'health.networkInformation.topologyHistory.ethernetdevices', value: 'ethernetdevices' },
    { text: 'health.networkInformation.topologyHistory.optimization', value: 'optimization' },
    { text: 'health.networkInformation.topologyHistory.powerModeFilter', value: 'powerMode' }
  ];

  preFilteredDevice: string = null;
  filterDevice: any = null;
  filterDevicesItems: any = [];
  filterDevicesDisabled: boolean = false;

  constructor(
    public plume: PlumeService,
    private router: Router,
    private route: ActivatedRoute,
    private mixpanel: MixpanelService,
    private translate: TranslateService,
    private store: Store,
    private customerService: CustomerService
  ) {
    this.route.queryParams?.subscribe((params) => {
      if (!params.mac) return;
      this.preFilteredDevice = params.mac;
    });
  }

  ngOnInit(): void {
    this.store
      .select(selectNodes)
      .pipe(untilDestroyed(this))
      .subscribe((nodes) => (this.nodes = nodes));
  }

  ngOnChanges(changes: any): void {
    if (changes.active && changes.active.currentValue) {
      this.expanded = true;
      this.topologyHistory();
    } else {
      this.expanded = false;
    }
  }

  toggleExpand(): void {
    const enabledModes = this.route.snapshot.params?.mode?.length ? this.route.snapshot.params.mode.split(',') : [];

    if (this.expanded) {
      this.expanded = false;
      this.mixpanel.storeEvent('RECENT_EVENTS_TIMELINE_EXPAND', { EXPANDED: false });
      this.router.navigate([
        'customer',
        this.plume.customerid,
        'location',
        this.plume.locationid,
        'timelines',
        enabledModes.filter((mode: string) => mode !== 'recentevents').join(',')
      ]);
    } else {
      this.expanded = true;
      this.mixpanel.storeEvent('RECENT_EVENTS_TIMELINE_EXPAND', { EXPANDED: true });
      this.router.navigate([
        'customer',
        this.plume.customerid,
        'location',
        this.plume.locationid,
        'timelines',
        [...enabledModes, 'recentevents'].join(',')
      ]);
    }
  }

  timeout(): void {
    setTimeout(() => {
      this.store
        .select(selectDevices)
        .pipe(take(1))
        .subscribe((devices) => {
          this.devices = devices;
          this.topologyHistory();
        });
    }, 1000);
  }

  initDeviceFilters(deviceList: any): void {
    this.filterDevice = null;
    this.filterDevicesItems = Object.keys(deviceList).map((mac: string) => ({
      text: deviceList[mac],
      value: mac
    }));
    if (this.preFilteredDevice) {
      const preFilteredDevice = this.devices.find((device) => device.mac === this.preFilteredDevice);
      if (!preFilteredDevice) return;
      this.toggleDeviceFilter({
        text: preFilteredDevice.nickname || preFilteredDevice.name,
        value: preFilteredDevice.mac
      });
    }
  }

  topologyHistory(): void {
    this.loading = true;

    if (!this.nodes || !this.devices) {
      this.timeout();
      return;
    }

    const endTime = moment();
    const startTime = moment(endTime.valueOf() - 24 * 60 * 60 * 1000).startOf('hour');
    const deviceList = {};

    this.events = [];

    this.customerService.topologyChanges$(startTime, endTime).subscribe(
      (topologyChanges) => {
        const historyEvents = [];
        const reducedTopologyChanges = [];

        topologyChanges.forEach((event: any) => {
          if (event.timestamp < startTime.valueOf()) {
            return;
          }

          const reducedEvent = {
            timestamp: event.timestamp,
            topology: []
          };

          event.topology.forEach((topology: any) => {
            const reducedTopology = {
              connectionState: topology.connectionState,
              id: topology.id,
              ethernetdevices: topology.ethernetdevices,
              wifiConfig: [],
              node: this.nodes.find((n: any) => topology.id === n.id) || {
                id: topology.id,
                nickname: 'health.networkInformation.topologyHistory.unclaimed',
                noclick: true
              }
            };
            topology.wifiConfig.forEach((wifiConfig: any) => {
              const reducedWifiConfig = {
                parentId: wifiConfig.parentId,
                freqBand: wifiConfig.freqBand,
                channel: wifiConfig.channel,
                devices: {}
              };

              if (wifiConfig.parentId.length) {
                reducedWifiConfig['parentNode'] = this.nodes.find((node: any) => wifiConfig.parentId === node.id) || {
                  id: wifiConfig.parentId,
                  nickname: 'health.networkInformation.topologyHistory.unclaimed',
                  noclick: true
                };
              }

              wifiConfig.devices?.forEach((device: IDevice) => {
                const deviceObject = this.devices?.find((d) => device.mac === d.mac) || {
                  mac: device.mac,
                  name: this.translate.instant('health.networkInformation.topologyHistory.unknown'),
                  noclick: true
                };

                reducedWifiConfig['devices'][device.mac] = deviceObject;
              });
              reducedTopology.wifiConfig.push(reducedWifiConfig);
            });
            reducedEvent.topology.push(reducedTopology);
          });
          reducedTopologyChanges.push(reducedEvent);
        });

        for (let i = 0; i < reducedTopologyChanges.length - 1; i++) {
          const current = reducedTopologyChanges[i];
          const previous = reducedTopologyChanges[i + 1];
          const diff = getDiff(previous.topology, current.topology, true);
          const events = [];

          diff.forEach((event: any) => {
            if (event.path.length > 1 && event.path.length < 4 && event.path[1] === 'ethernetdevices') {
              event.type = 'ethernetdevices';
              event.node = current.topology[event.path[0]].node || null;
              if (event.op === 'delete') {
                event.device = Array.isArray(event.oldVal) ? event.oldVal[0] : event.oldVal;
              }
              if (event.op === 'add') {
                event.device = Array.isArray(event.val) ? event.val[0] : event.val;
              }
              events.push(event);
            }
            if (event.path.length === 2 && event.path[1] === 'connectionState') {
              event.type = 'connectionState';
              event.node = current.topology[event.path[0]].node || null;

              if (event.val === 'connected') {
                current.topology[event.path[0]].wifiConfig.forEach((band: any) => {
                  if (band.devices && Object.keys(band.devices).length) {
                    Object.keys(band.devices).forEach((mac: string) => {
                      const channel = band.channel;
                      const channelColor = this.channelHelper.getColor(channel);
                      const channelTextColor = this.channelHelper.getContrastColor(channelColor);

                      deviceList[mac] = band.devices[mac].name;

                      events.push({
                        op: 'add',
                        type: 'devices',
                        node: current.topology[event.path[0]].node || null,
                        device: band.devices[mac] || null,
                        channel,
                        channelColor,
                        channelTextColor
                      });
                    });
                  }

                  if (band.parentId.length) {
                    event.channel = band.channel;
                    event.channelColor = this.channelHelper.getColor(event.channel);
                    event.channelTextColor = this.channelHelper.getContrastColor(event.channelColor);
                    event.parentNode = band.parentNode;
                  }
                });
              }

              if (event.val === 'disconnected') {
                previous.topology[event.path[0]].wifiConfig.forEach((band: any) => {
                  if (band.parentId.length) {
                    event.channel = band.channel;
                    event.channelColor = this.channelHelper.getColor(event.channel);
                    event.channelTextColor = this.channelHelper.getContrastColor(event.channelColor);
                    event.parentNode = band.parentNode;
                  }
                });
              }

              events.push(event);
            }

            if (event.path.length === 4 && event.path[3] === 'parentId') {
              event.type = 'parentId';
              event.node = current.topology[event.path[0]].node || null;

              if (event.val !== '' && event.oldVal !== '') {
                event.status = 'disconnected';
                event.newNode = this.nodes.find((node: any) => event.oldVal === node.id) || null;

                if (!event.newNode) {
                  event.newNode = {
                    id: event.oldVal,
                    nickname: 'health.networkInformation.topologyHistory.unclaimed',
                    noclick: true
                  };
                }

                event.channel = previous.topology[event.path[0]].wifiConfig[event.path[2]].channel;
                event.channelColor = this.channelHelper.getColor(event.channel);
                event.channelTextColor = this.channelHelper.getContrastColor(event.channelColor);

                events.push({ ...event });

                event.status = 'connected';
                event.newNode = this.nodes.find((node: any) => event.val === node.id) || null;

                if (!event.newNode) {
                  event.newNode = {
                    id: event.val,
                    nickname: 'health.networkInformation.topologyHistory.unclaimed',
                    noclick: true
                  };
                }

                event.channel = current.topology[event.path[0]].wifiConfig[event.path[2]].channel;
                event.channelColor = this.channelHelper.getColor(event.channel);
                event.channelTextColor = this.channelHelper.getContrastColor(event.channelColor);

                events.push({ ...event });
              } else {
                if (event.oldVal === '') {
                  event.status = 'connected';
                  event.newNode = this.nodes.find((node: any) => event.val === node.id) || null;

                  if (!event.newNode) {
                    event.newNode = {
                      id: event.val,
                      nickname: 'health.networkInformation.topologyHistory.unclaimed',
                      noclick: true
                    };
                  }

                  event.channel = current.topology[event.path[0]].wifiConfig[event.path[2]].channel;
                  event.channelColor = this.channelHelper.getColor(event.channel);
                  event.channelTextColor = this.channelHelper.getContrastColor(event.channelColor);
                }

                if (event.val === '') {
                  event.status = 'disconnected';
                  event.newNode = this.nodes.find((node: any) => event.oldVal === node.id) || null;

                  if (!event.newNode) {
                    event.newNode = {
                      id: event.oldVal,
                      nickname: 'health.networkInformation.topologyHistory.unclaimed',
                      noclick: true
                    };
                  }

                  event.channel = previous.topology[event.path[0]].wifiConfig[event.path[2]].channel;
                  event.channelColor = this.channelHelper.getColor(event.channel);
                  event.channelTextColor = this.channelHelper.getContrastColor(event.channelColor);
                }

                events.push(event);
              }
            }

            if (event.path.length === 4 && event.path[3] === 'channel') {
              event.type = 'channel';
              event.node = current.topology[event.path[0]].node || null;
              event.radio = current.topology[event.path[0]].wifiConfig[event.path[2]];
              event.oldValColor = this.channelHelper.getColor(event.oldVal);
              event.oldValTextColor = this.channelHelper.getContrastColor(event.oldValColor);
              event.valColor = this.channelHelper.getColor(event.val);
              event.valTextColor = this.channelHelper.getContrastColor(event.valColor);

              events.push(event);
            }

            if ((event.path.length === 4 || event.path.length === 5) && event.path[3] === 'devices') {
              event.type = 'devices';
              event.node = current.topology[event.path[0]].node || null;

              if (event.op === 'add') {
                if (event.path.length === 4) {
                  event.device = event.val[Object.keys(event.val)[0]];
                }

                if (event.path.length === 5) {
                  event.device = event.val;
                }

                event.channel = current.topology[event.path[0]].wifiConfig[event.path[2]].channel;
                event.channelColor = this.channelHelper.getColor(event.channel);
                event.channelTextColor = this.channelHelper.getContrastColor(event.channelColor);
              }

              if (event.op === 'delete') {
                if (event.path.length === 4) {
                  event.device = event.oldVal[Object.keys(event.oldVal)[0]];
                }

                if (event.path.length === 5) {
                  event.device = event.oldVal;
                }

                event.channel = previous.topology[event.path[0]].wifiConfig[event.path[2]].channel;
                event.channelColor = this.channelHelper.getColor(event.channel);
                event.channelTextColor = this.channelHelper.getContrastColor(event.channelColor);
              }

              deviceList[event.device.mac] = event.device.name;

              events.push(event);
            }
          });

          if (events.length) {
            historyEvents.push({
              timestamp: current.timestamp,
              events
            });
          }
        }

        this.initDeviceFilters(deviceList);

        this.customerService.optimizeRequests$(startTime, endTime).subscribe(
          (optimizeRequests) => {
            optimizeRequests.forEach((optimizationRequest) => {
              const optimization = JSON.parse(optimizationRequest);

              historyEvents.push({
                timestamp: new Date(optimization.createdAt).getTime(),
                events: [
                  {
                    type: 'optimization',
                    triggers: optimization.triggers
                  }
                ]
              });
            });

            this.loading = false;
            this.allEvents = historyEvents.sort((a: any, b: any) => b.timestamp - a.timestamp);

            this.filter();
          },
          () => {
            this.loading = false;
            this.allEvents = historyEvents;

            this.filter();
          }
        );
      },
      () => {
        this.loading = false;
        this.allEvents = [];

        this.filter();
      }
    );
  }

  toggleFilter(filters: any): void {
    const devicesEnabled = filters.includes('devices');

    if (devicesEnabled) {
      this.filterDevicesDisabled = false;
    } else {
      this.filterDevicesDisabled = true;
      this.toggleDeviceFilter(null);
    }

    this.filters = filters;
    this.filter();
  }

  toggleDeviceFilter(device: { text: string; value: string }): void {
    this.filterDevice = device;
    this.filter();
  }

  filter(): void {
    const filtered = [];

    this.allEvents.forEach((history: any) => {
      const events = [];

      history.events.forEach((event: any) => {
        let pushed: boolean = false;

        if (this.filterDevice) {
          if (event.type === 'devices' && event.device.mac === this.filterDevice.value) {
            events.push(event);
          }
        } else {
          for (const filter of this.filters) {
            if (pushed === false) {
              if (filter === event.type) {
                pushed = true;
                events.push(event);
              } else {
                if (filter === 'powerMode' && event.type === 'optimization') {
                  if (event.triggers?.includes('ExitPowerMode') || event.triggers?.includes('EnterPowerMode')) {
                    pushed = true;
                    events.push(event);
                  }
                }
              }
            }
          }
        }
      });

      if (events.length) {
        filtered.push({
          timestamp: history.timestamp,
          events
        });
      }
    });

    this.allFilteredEvents = filtered;

    this.prepareChart(filtered);
  }

  prepareChart(events: any[]): void {
    const groups = {};
    const hours = [];
    let maxCount = 0;

    events.forEach((event: any) => {
      const date = new Date(event.timestamp);
      const bucket = date.toISOString().slice(0, 13);

      if (groups[bucket]) {
        groups[bucket].push(event);
      } else {
        groups[bucket] = [event];
      }
    });

    for (let i = 0; i < 25; i++) {
      const hour = new Date(Date.now() - i * 60 * 60 * 1000);
      const bucket = hour.toISOString().slice(0, 13);

      if (groups[bucket]) {
        if (groups[bucket].length > maxCount) {
          maxCount = groups[bucket].length;
        }

        hours.push({ timestamp: new Date(bucket + ':00:00.000Z'), group: groups[bucket] });
      } else {
        hours.push({ timestamp: new Date(bucket + ':00:00.000Z'), group: [] });
      }
    }

    if (!this.selectedBucket) {
      this.events = this.allFilteredEvents;
    } else {
      this.events = groups[new Date(this.selectedBucket).toISOString().slice(0, 13)] || [];
    }

    this.chart = {
      maxCount,
      hours: hours.reverse()
    };
  }

  selectEvents(event?: any): void {
    if (!event || event.timestamp.getTime() === this.selectedBucket) {
      this.events = this.allFilteredEvents;
      this.selectedBucket = null;
    } else {
      this.events = event.events;
      this.selectedBucket = event.timestamp.getTime();
    }
  }

  goto(mode: string, id: string): void {
    this.router.navigate(['customer', this.plume.customerid, 'location', this.plume.locationid, mode, id]);
  }

  downloadCSV(): void {
    const data = [];
    const dateHeader = this.translate.instant('health.networkInformation.topologyHistory.date');
    const timeHeader = this.translate.instant('health.networkInformation.topologyHistory.time');
    const eventHeader = this.translate.instant('health.networkInformation.topologyHistory.event');

    this.events.forEach((event: any) => {
      event.events.forEach((subevent: any) => {
        data.push({
          [dateHeader]: new Date(event.timestamp).toLocaleDateString(),
          [timeHeader]: new Date(event.timestamp).toLocaleTimeString(),
          [eventHeader]: this.eventToText(subevent)
        });
      });
    });

    this.mixpanel.storeEvent('RECENT_EVENTS_SAVE_CSV');

    const universalBOM = '\uFEFF';
    const worker = new Parser();
    const csv = worker.parse(data);
    const blob = new Blob([universalBOM + csv], { type: 'text/csv;charset=utf8;' });
    const filename = 'recent_events_' + (this.selectedBucket ? this.selectedBucket : Date.now()) + '.csv';

    this.helper.download(blob, filename);
  }

  eventToText(event: any): string {
    const nodeName =
      event.node?.nickname || event.node?.defaultName
        ? ' (' + (event.node.nickname || event.node.defaultName) + ')'
        : '';

    if (event.type === 'connectionState') {
      if (event.parentNode) {
        const parentNodeName =
          event.parentNode.nickname || event.parentNode.defaultName
            ? ' (' + (event.parentNode.nickname || event.parentNode.defaultName) + ')'
            : '';
        const toFrom = this.translate.instant(
          'health.networkInformation.topologyHistory.' + (event.val === 'connected' ? 'to' : 'from')
        );
        const channelText = this.translate.instant('health.networkInformation.topologyHistory.onChannel');

        return (
          event.node.id +
          nodeName +
          ' ' +
          event.val +
          ' ' +
          toFrom +
          ' ' +
          event.parentNode.id +
          parentNodeName +
          ' ' +
          channelText +
          ' ' +
          event.channel
        );
      } else {
        const gateway = this.translate.instant('health.networkInformation.topologyHistory.asGateway');
        return event.node.id + nodeName + ' ' + event.val + ' ' + gateway;
      }
    }

    if (event.type === 'parentId') {
      const newNodeName =
        event.newNode?.nickname || event.newNode?.defaultName
          ? ' (' + (event.newNode.nickname || event.newNode.defaultName) + ')'
          : '';
      const text = this.translate.instant(
        'health.networkInformation.topologyHistory.' +
          (event.status === 'connected' ? 'connectedTo' : 'disconnectedFrom')
      );
      const channelText = this.translate.instant('health.networkInformation.topologyHistory.onChannel');

      return (
        event.node.id +
        nodeName +
        ' ' +
        text +
        ' ' +
        event.newNode.id +
        newNodeName +
        ' ' +
        channelText +
        ' ' +
        event.channel
      );
    }

    if (event.type === 'channel') {
      const text = this.translate.instant('health.networkInformation.topologyHistory.changedBackhaulChannelFrom');
      const text2 = this.translate.instant('health.networkInformation.topologyHistory.to');
      const text3 = this.translate.instant('health.networkInformation.topologyHistory.destinationRadio');
      return (
        event.node.id +
        nodeName +
        ' ' +
        text +
        ' ' +
        event.oldVal +
        ' ' +
        text2 +
        ' ' +
        event.val +
        ' ' +
        text3 +
        ' ' +
        event.radio.freqBand
      );
    }

    if (event.type === 'devices') {
      const deviceName = event.device?.name ? ' (' + event.device.name + ')' : '';
      const channelText = this.translate.instant('health.networkInformation.topologyHistory.onChannel');

      if (event.op === 'add') {
        const text = this.translate.instant('health.networkInformation.topologyHistory.connectedTo');
        return (
          event.device.mac +
          deviceName +
          ' ' +
          text +
          ' ' +
          event.node.id +
          nodeName +
          ' ' +
          channelText +
          ' ' +
          event.channel
        );
      }

      if (event.op === 'delete') {
        const text = this.translate.instant('health.networkInformation.topologyHistory.disconnectedFrom');
        return (
          event.device.mac +
          deviceName +
          ' ' +
          text +
          ' ' +
          event.node.id +
          nodeName +
          ' ' +
          channelText +
          ' ' +
          event.channel
        );
      }
    }

    if (event.type === 'optimization') {
      const text = this.translate.instant('health.networkInformation.topologyHistory.optimizationRequest');
      return text + ' ' + event.triggers.join(', ');
    }

    return '';
  }
}
