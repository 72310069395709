<div class="content">
  <div class="strip">
    <icon *ngIf="data.type === 'pod'" [path]="iconURL" class="column size-2" [class.node]="data.type === 'pod'"></icon>

    <div *ngIf="data.type === 'device'" class="icon-container">
      <icon [path]="iconURL" class="device-icon" class="size-2"> </icon>
    </div>

    <div class="column name">
      <div class="ellipsis">{{ type === 'node' ? data.name : (data.name | macAddr : true) }}</div>
      <div class="ellipsis id">{{ type === 'node' ? data.id : (data.id | macAddr) }}</div>
    </div>
    <div class="column connection">
      <div *ngIf="data.status === 'connected' && !data.qoe?.rawQoe?.mlo">{{ data.connection | translate }}</div>
      <div *ngIf="data.status === 'connected' && data.qoe?.rawQoe?.mlo">{{ 'mlo' | translate }}</div>
    </div>
    <div class="column busy" *ngIf="data.connection !== 'qoe.ethernet' && data.status === 'connected' && data.rawQoe">
      <div class="wrapper" *ngIf="data.qoe.busy">
        <div class="point low"></div>
        <div class="point medium"></div>
        <div class="point high"></div>
      </div>
    </div>
    <div class="column qoe" *ngIf="data.connection !== 'qoe.ethernet' && data.status === 'connected' && data.rawQoe">
      <health-dots [rssiOrQoe]="data" tooltip="{{ data.qoe.qoe }}"></health-dots>
    </div>
    <div
      class="column rssi colors"
      [ngClass]="data.qoe.qoe > 4 ? 'good' : data.qoe.qoe < 2 ? 'poor' : data.qoe.qoe < 3 ? 'warn' : 'fair'"
      *ngIf="data.connection !== 'qoe.ethernet' && data.status === 'connected' && data.rawQoe && data.qoe.rssi"
    >
      {{ data.qoe.rssi }} dBm
    </div>
    <div
      class="column phy-rate colors"
      [ngClass]="data.qoe.qoe > 4 ? 'good' : data.qoe.qoe < 2 ? 'poor' : data.qoe.qoe < 3 ? 'warn' : 'fair'"
      *ngIf="data.connection !== 'qoe.ethernet' && data.status === 'connected' && data.rawQoe"
    >
      <div class="wrapper" *ngIf="data.qoe.phyRateEff && data.qoe.phyRateEff <= 100">{{ data.qoe.phyRateEff }} %</div>
    </div>
    <div
      class="column congestion colors"
      [ngClass]="data.qoe.qoe > 4 ? 'good' : data.qoe.qoe < 2 ? 'poor' : data.qoe.qoe < 3 ? 'warn' : 'fair'"
      *ngIf="data.connection !== 'qoe.ethernet' && data.status === 'connected' && data.rawQoe"
    >
      {{ data.qoe.congestion }} %
    </div>
    <div
      class="column potentialThroughput"
      *ngIf="data.connection !== 'qoe.ethernet' && data.status === 'connected' && data.rawQoe"
    >
      {{ data.qoe.potentialThroughput }} Mbps
    </div>
    <div
      class="column no-data"
      *ngIf="(!data.rawQoe || data.connection === 'qoe.ethernet') && data.status === 'connected'"
    >
      {{ 'qoe.noQoeData' | translate }}
    </div>
    <div class="column no-data" *ngIf="data.status !== 'connected'">
      {{ 'qoe.offline' | translate }}
    </div>
    <icon
      name="icon-arrow-down"
      class="expander"
      [class.rotate180]="expanded"
      (click)="toggleExpand()"
      *ngIf="data.rawQoe"
    ></icon>
  </div>
  <div class="mobile">
    <div class="column connection" *ngIf="data.status === 'connected'">
      <div class="title">{{ 'qoe.headers.connection' | translate }}</div>
      <div>{{ data.connection | translate }}</div>
    </div>
    <div class="column busy" *ngIf="data.connection !== 'qoe.ethernet' && data.status === 'connected' && data.rawQoe">
      <div class="title">{{ 'qoe.headers.busy' | translate }}</div>
      <div class="wrapper" *ngIf="data.qoe.busy">
        <div class="point low"></div>
        <div class="point medium"></div>
        <div class="point high"></div>
      </div>
    </div>
    <div class="column qoe" *ngIf="data.connection !== 'qoe.ethernet' && data.status === 'connected' && data.rawQoe">
      <div class="title">{{ 'qoe.headers.qoe' | translate }}</div>
      <health-dots [rssiOrQoe]="data" tooltip="{{ data.qoe.qoe }}"></health-dots>
    </div>
    <div
      class="column rssi colors"
      [ngClass]="data.qoe.qoe > 4 ? 'good' : data.qoe.qoe < 2 ? 'poor' : data.qoe.qoe < 3 ? 'warn' : 'fair'"
      *ngIf="data.connection !== 'qoe.ethernet' && data.status === 'connected' && data.rawQoe"
    >
      <div class="title">{{ 'qoe.headers.rssi' | translate }}</div>
      {{ data.qoe.rssi }} dBm
    </div>
    <div
      class="column phy-rate colors"
      [ngClass]="data.qoe.qoe > 4 ? 'good' : data.qoe.qoe < 2 ? 'poor' : data.qoe.qoe < 3 ? 'warn' : 'fair'"
      *ngIf="data.connection !== 'qoe.ethernet' && data.status === 'connected' && data.rawQoe"
    >
      <div class="title">{{ 'qoe.headers.effPhyRate' | translate }}</div>
      <div class="wrapper" *ngIf="data.qoe.phyRateEff && data.qoe.phyRateEff <= 100">{{ data.qoe.phyRateEff }} %</div>
    </div>
    <div
      class="column congestion colors"
      [ngClass]="data.qoe.qoe > 4 ? 'good' : data.qoe.qoe < 2 ? 'poor' : data.qoe.qoe < 3 ? 'warn' : 'fair'"
      *ngIf="data.connection !== 'qoe.ethernet' && data.status === 'connected' && data.rawQoe"
    >
      <div class="title">{{ 'qoe.headers.congestion' | translate }}</div>
      {{ data.qoe.congestion }} %
    </div>
    <div
      class="column potentialThroughput"
      *ngIf="data.connection !== 'qoe.ethernet' && data.status === 'connected' && data.rawQoe"
    >
      <div class="title">{{ 'qoe.headers.predThroughput' | translate }}</div>
      {{ data.qoe.potentialThroughput }} Mbps
    </div>
    <div class="no-data" *ngIf="(!data.rawQoe || data.connection === 'qoe.ethernet') && data.status === 'connected'">
      {{ 'qoe.noQoeData' | translate }}
    </div>
    <div class="no-data" *ngIf="data.status !== 'connected'">
      {{ 'qoe.offline' | translate }}
    </div>
  </div>
  <div class="details" *ngIf="expanded && showLiveCharts">
    <div class="detail-chart">
      <div class="detail-collapse">
        <div (click)="hideGraph('liveNeedBasedScore')" class="detail-label">
          {{ 'qoe.charts.needAndUsageBasedScore' | translate }}
        </div>
        <div *ngIf="isShowChart('liveNeedBasedScore')" class="detail-label"></div>
        <icon
          (click)="hideGraph('liveNeedBasedScore')"
          name="icon-triangle-down"
          [class.rotate180]="isShowChart('liveNeedBasedScore')"
        ></icon>
      </div>
      <qoe-live-chart-zoom
        *ngIf="isShowChart('liveNeedBasedScore')"
        [data]="[data.liveCharts?.needBasedScore, data.liveCharts?.usageBasedScore]"
        [scale]="{ range: [0, 5], symbol: '', thresholdValue: 2, above: false }"
      ></qoe-live-chart-zoom>
    </div>
    <div class="detail-chart">
      <div class="detail-collapse">
        <div (click)="hideGraph('liveRssi')" class="detail-label">
          {{ 'qoe.charts.rssi' | translate }}
        </div>
        <div *ngIf="isShowChart('liveRssi')" class="detail-label"></div>
        <icon
          (click)="hideGraph('liveRssi')"
          name="icon-triangle-down"
          [class.rotate180]="isShowChart('liveRssi')"
        ></icon>
      </div>
      <div class="rssi-toggler" *ngIf="isShowChart('liveRssi')">
        <icon name="icon-info" (click)="showModal()"></icon>
        <slidetoggler [options]="rssiModeItems" (toggle)="setRssiMode($event)"></slidetoggler>
      </div>
      <qoe-live-chart-zoom
        *ngIf="rssiMode === 'static' && isShowChart('liveRssi')"
        [data]="[data.liveCharts?.snr]"
        [scale]="{ range: rssiRange.static, symbol: 'dBm', thresholdValue: -73, above: false }"
      ></qoe-live-chart-zoom>
      <qoe-live-chart-zoom
        *ngIf="rssiMode === 'dynamic' && isShowChart('liveRssi')"
        [data]="[data.liveCharts?.dynamicRssi]"
        [scale]="{ range: rssiRange.dynamic, symbol: 'dBm', thresholdValue: -73, above: false }"
      ></qoe-live-chart-zoom>
    </div>
    <div class="detail-chart">
      <div class="detail-collapse">
        <div (click)="hideGraph('liveUtilizationInterference')" class="detail-label">
          {{ 'qoe.charts.utilizationAndInterference' | translate }}
        </div>
        <div *ngIf="isShowChart('liveUtilizationInterference')" class="detail-label"></div>
        <icon
          (click)="hideGraph('liveUtilizationInterference')"
          name="icon-triangle-down"
          [class.rotate180]="isShowChart('liveUtilizationInterference')"
        ></icon>
      </div>
      <qoe-live-chart-zoom
        *ngIf="isShowChart('liveUtilizationInterference')"
        [data]="[data.liveCharts?.channelUtilization, data.liveCharts?.interference]"
        [scale]="{ range: [0, 100], symbol: '%', thresholdValue: 75, above: true }"
      ></qoe-live-chart-zoom>
    </div>
    <div class="detail-chart">
      <div class="detail-collapse">
        <div (click)="hideGraph('livePacketRetry')" class="detail-label">
          {{ 'qoe.charts.packetRetry' | translate }}
        </div>
        <div *ngIf="isShowChart('livePacketRetry')" class="detail-label"></div>
        <icon
          (click)="hideGraph('livePacketRetry')"
          name="icon-triangle-down"
          [class.rotate180]="isShowChart('livePacketRetry')"
        ></icon>
      </div>
      <qoe-live-chart-zoom
        *ngIf="isShowChart('livePacketRetry')"
        [data]="[data.liveCharts?.rxPrr, data.liveCharts?.txPrr]"
        [scale]="{ range: [0, 100], symbol: '%', thresholdValue: 75, above: true }"
      ></qoe-live-chart-zoom>
    </div>
    <div class="detail-chart">
      <div class="detail-collapse">
        <div (click)="hideGraph('livePhyRate')" class="detail-label">
          {{ 'qoe.charts.phyRate' | translate }}
        </div>
        <div *ngIf="isShowChart('livePhyRate')" class="detail-label"></div>
        <icon
          (click)="hideGraph('livePhyRate')"
          name="icon-triangle-down"
          [class.rotate180]="isShowChart('livePhyRate')"
        ></icon>
      </div>
      <qoe-live-chart-zoom
        *ngIf="isShowChart('livePhyRate')"
        [data]="[data.liveCharts?.rxPhyRate, data.liveCharts?.txPhyRate]"
        [scale]="{ range: null, symbol: 'Mbps' }"
      ></qoe-live-chart-zoom>
    </div>
    <div class="detail-chart">
      <div class="detail-collapse">
        <div (click)="hideGraph('livePotentialThroughput')" class="detail-label">
          {{ 'qoe.charts.potentialThroughput' | translate }}
        </div>
        <div *ngIf="isShowChart('livePotentialThroughput')" class="detail-label"></div>
        <icon
          (click)="hideGraph('livePotentialThroughput')"
          name="icon-triangle-down"
          [class.rotate180]="isShowChart('livePotentialThroughput')"
        ></icon>
      </div>
      <qoe-live-chart-zoom
        *ngIf="isShowChart('livePotentialThroughput')"
        [data]="[data.liveCharts?.potentialThroughput]"
        [scale]="{ range: null, symbol: 'Mbps' }"
      ></qoe-live-chart-zoom>
    </div>

    <div class="detail-chart">
      <div class="detail-collapse">
        <div (click)="hideGraph('liveCurrentUsage')" class="detail-label">
          {{ 'qoe.charts.currentUsage' | translate }}
        </div>
        <div *ngIf="isShowChart('liveCurrentUsage')" class="detail-label"></div>
        <icon
          (click)="hideGraph('liveCurrentUsage')"
          name="icon-triangle-down"
          [class.rotate180]="isShowChart('liveCurrentUsage')"
        ></icon>
      </div>
      <qoe-live-chart-zoom
        *ngIf="isShowChart('liveCurrentUsage')"
        [data]="[data.liveCharts?.currentUsage]"
        [scale]="{ range: null, symbol: 'Mbps' }"
      ></qoe-live-chart-zoom>
    </div>

    <div class="detail-chart" *ngIf="powerCapable">
      <div class="detail-collapse">
        <div (click)="hideGraph('isLpm')" class="detail-label">
          {{ 'qoe.charts.isLpm' | translate }}
        </div>
        <div *ngIf="isShowChart('isLpm')" class="detail-label"></div>
        <icon (click)="hideGraph('isLpm')" name="icon-triangle-down" [class.rotate180]="isShowChart('isLpm')"></icon>
      </div>
      <qoe-live-chart-zoom
        *ngIf="isShowChart('isLpm')"
        [data]="[data.liveCharts?.isLpm]"
        [scale]="{ range: [0, 1], symbol: '' }"
      ></qoe-live-chart-zoom>
    </div>

    <div class="qoeDetails" *ngIf="data.rawQoe">
      <collapsiblebox
        mode="bare"
        [showDivider]="false"
        [show]="showCurrentQoe"
        [collapsible]="true"
        (toggle)="showCurrentQoe = !showCurrentQoe"
      >
        <ng-container header>{{ 'qoe.charts.mostRecentMeasurements' | translate }}</ng-container>
      </collapsiblebox>
      <div class="list" [class.show]="showCurrentQoe" *ngFor="let qoe of data.rawQoe | keyvalue">
        <div class="left">{{ qoe.key }}</div>
        <div class="right">{{ qoe.value }}</div>
      </div>
    </div>
  </div>
  <div class="recommendation" *ngFor="let recommendation of data.recommendations">
    <icon [path]="recommendation.icon" class="size-1_4"></icon>
    <div class="description">
      {{ recommendation.description | translate }}
    </div>
    <div class="actions" *ngIf="recommendation.actions.length">
      <div class="action" *ngFor="let action of recommendation.actions" (click)="resolveAction(action)">
        {{ action | translate }}
      </div>
    </div>
  </div>
</div>
