import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren
} from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable, Subscription } from 'rxjs';
import { IGuidedTroubleshootMessage, IGuidedTroubleshootSelectedOption } from 'src/app/lib/interfaces/interface';

@UntilDestroy()
@Component({
  selector: 'chatWindow',
  templateUrl: './chatWindow.component.html',
  styleUrls: ['./chatWindow.component.scss']
})
export class ChatWindowComponent implements OnInit, OnDestroy {
  @Input()
  messages$: Observable<IGuidedTroubleshootMessage[]>;

  @Output()
  overlayAction = new EventEmitter<void>();

  @Output()
  hideChatDialog = new EventEmitter<void>();

  @Output()
  selectButton = new EventEmitter<IGuidedTroubleshootSelectedOption>();

  @ViewChildren('messageRef') messageRefs: QueryList<ElementRef>;

  messagesSubscription: Subscription;

  constructor() {}

  ngOnInit(): void {
    this.messagesSubscription = this.messages$?.subscribe((messages) => {
      if (messages && messages.length > 0) {
        setTimeout(() => {
          this.messagesSubscription = this.messages$?.subscribe((messages) => {
            if (messages && messages.length > 0) {
              const lastMessage = messages[messages.length - 1];
              if (lastMessage) {
                const lastMessageRef = this.messageRefs.last;
                if (lastMessageRef) {
                  const view = lastMessageRef.nativeElement;
                  view.scrollIntoView({ behavior: 'smooth', block: 'end' });
                }
              }
            }
          });
        }, 100);
      }
    });
  }

  hasSelections(selections: any): boolean {
    return selections && Object.keys(selections).length > 0;
  }

  getObjectKeys(obj: any): string[] {
    return Object.keys(obj);
  }

  ngOnDestroy(): void {
    if (this.messagesSubscription) {
      this.messagesSubscription.unsubscribe();
    }
  }
}
