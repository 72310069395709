import { Component, Input, ElementRef, OnChanges, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { D3Service } from 'src/app/lib/d3/service';
import { BarChart, BarChartData } from 'src/app/lib/d3/models/charts/bar.chart';
import { Tick } from 'src/app/lib/d3/models/objects/tick';
import { Bar } from 'src/app/lib/d3/models/objects/bar';
import * as erdm from 'element-resize-detector';
import moment from 'moment';

@Component({
  selector: 'barchart',
  templateUrl: './barchart.component.html',
  styleUrls: ['./barchart.component.scss']
})
export class BarChartVisualComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input()
  data: BarChartData[];

  @ViewChild('canvas')
  canvas: ElementRef<HTMLDivElement>;

  tooltips: any[];

  width: number;
  height: number = 0;
  chart: BarChart;
  bars: { [group: string]: Bar[] };
  groups: string[];
  xAxis: Tick[];
  yAxis: Tick[];
  margins = {
    top: 40,
    right: 40,
    bottom: 60,
    left: 40
  };

  biggestDataPoint = 0;

  erd: any = erdm({ strategy: 'scroll' });

  constructor(private d3: D3Service) {
    this.chart = this.d3.generate('barchart');
  }

  ngAfterViewInit(): void {
    this.erd.listenTo(this.canvas.nativeElement, () => {
      this.render();
    });
  }

  ngOnChanges(): void {
    if (this.canvas) this.render();
  }

  render(): void {
    this.groups = Array(29)
      .fill(0)
      .map((_, i) => moment().subtract(i, 'day').format('YYYY-MM-DD'));

    this.bars = this.chart.update(
      this.data,
      this.canvas.nativeElement.clientWidth,
      this.canvas.nativeElement.clientHeight,
      this.margins,
      this.groups
    );

    this.biggestDataPoint = this.chart.getBiggestDataPoint();

    this.width = this.chart.calculateWidth();
    this.height = this.chart.calculateHeight();

    this.xAxis = this.chart.xAxis();
    this.yAxis = this.chart.yAxis();

    this.tooltips = this.generateTooltips();
  }

  generateTooltips() {
    const width = this.width / 29;
    const tooltips = [];

    Object.keys(this.bars)
      .sort((a: string, b: string) => new Date(b).getTime() - new Date(a).getTime())
      .forEach((timestamp) => {
        const left = this.chart.scaleValue(timestamp);
        const leftTransform = '0';
        const leftPosition = left < this.width / 2 ? width / 2 + 10 : 'auto';
        const rightPosition = left >= this.width / 2 ? width / 2 + 10 : 'auto';
        const height = this.chart.calculateHeight();
        const tooltip = {
          key: timestamp,
          date: moment(timestamp).format('ll'),
          data: this.bars[timestamp].map((bar) => ({
            series: bar.class,
            value: bar.count
          })),
          show: false,
          region: {
            width,
            left,
            height
          },
          style: {
            'top.px': height / 2,
            'left.px': leftPosition,
            'right.px': rightPosition,
            transform: `translate(${leftTransform}, -50%)`
          }
        };
        tooltips.push(tooltip);
      });
    return tooltips;
  }

  showTooltip(data: any, show: boolean): void {
    data.show = show;
  }

  ngOnDestroy(): void {
    this.erd.uninstall(this.canvas.nativeElement);
  }
}
