<div class="loading" [class.show]="!bars || biggestDataPoint === 0">
  <div *ngIf="!bars" class="text">{{ 'aisecurity.chart.loadingEvents' | translate }}</div>
  <div *ngIf="bars && biggestDataPoint === 0" class="text">
    {{ 'aisecurity.chart.noEvents' | translate }}
  </div>
</div>
<div class="barchart" #canvas>
  <svg>
    <defs>
      <linearGradient id="barGradient" gradientTransform="rotate(90)">
        <stop class="barGradient-start" offset="5%"></stop>
        <stop class="barGradient-stop" offset="95%"></stop>
      </linearGradient>
      <linearGradient id="regionGradient" gradientTransform="rotate(90)">
        <stop class="region-start" offset="0%" />
        <stop class="region-stop" offset="100%" />
      </linearGradient>
      <filter id="shadow" x="-10%" y="-1%" width="120%" height="110%">
        <feGaussianBlur in="SourceAlpha" stdDeviation="1" result="blur" />
        <feOffset in="blur" dx="0" dy="1" result="offsetBlur" />
        <feFlood flood-color="#000000" flood-opacity="0.2" result="offsetColor" />
        <feComposite in="offsetColor" in2="offsetBlur" operator="in" result="offsetComposite" />
        <feBlend in="SourceGraphic" in2="offsetComposite" mode="normal" />
      </filter>
    </defs>
    <svg:g [attr.transform]="'translate(' + margins.left + ',' + margins.top + ')'">
      <svg:g class="tooltips" [attr.width]="width">
        <rect
          *ngFor="let tooltip of tooltips"
          class="region"
          [class.hovered]="tooltip.show"
          [attr.width]="tooltip.region.width"
          [attr.height]="tooltip.region.height"
          [attr.x]="tooltip.region.left"
          [attr.y]="0"
          rx="5"
          (mouseenter)="showTooltip(tooltip, true)"
          (mouseleave)="showTooltip(tooltip, false)"
        ></rect>
      </svg:g>
      <svg:rect class="axis" [attr.x]="0" [attr.y]="-10" [attr.width]="1" [attr.height]="height + 10"></svg:rect>
      <svg:rect class="axis" [attr.x]="0" [attr.y]="height" [attr.width]="width" [attr.height]="1"></svg:rect>
      <svg:g [attr.class]="tick.class" [tickVisual]="tick" *ngFor="let tick of xAxis"></svg:g>
      <svg:g [attr.class]="tick.class" [tickVisual]="tick" *ngFor="let tick of yAxis"></svg:g>
      <svg:g *ngFor="let group of groups" [attr.transform]="'translate(' + chart.fxScale(group) + ',0)'">
        <svg:g [attr.class]="bar.class" [barVisual]="bar" *ngFor="let bar of bars[group]"></svg:g>
      </svg:g>
      <foreignObject
        *ngFor="let tooltip of tooltips"
        [attr.width]="tooltip.region.width"
        [attr.height]="tooltip.region.height"
        [attr.x]="tooltip.region.left"
        [attr.y]="0"
        (mouseenter)="showTooltip(tooltip, true)"
        (mouseleave)="showTooltip(tooltip, false)"
      >
        <div class="info" [ngStyle]="tooltip.style" *ngIf="tooltip.show">
          <div class="date">{{ tooltip.date }}</div>
          <div class="data" *ngFor="let data of tooltip.data">
            <div class="circle" [class]="data.series"></div>
            <ng-container>
              {{ 'aisecurity.' + data.series | translate }}: &nbsp;<b>{{ data.value }}</b>
            </ng-container>
          </div>
        </div>
      </foreignObject>
    </svg:g>
  </svg>
</div>
