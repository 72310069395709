<svg:rect
  [attr.class]="barVisual.class + ' bar'"
  [attr.x]="barVisual.x"
  [attr.y]="barVisual.y"
  [attr.width]="barVisual.width"
  [attr.height]="barVisual.height"
  [attr.rx]="barVisual.rx"
  [attr.ry]="barVisual.ry"
  [attr.clip-path]="barVisual.clip"
  [attr.fill]="barVisual.fill"
></svg:rect>
<svg:rect
  [attr.class]="barVisual.class + ' overlay'"
  [attr.x]="barVisual.x"
  [attr.y]="barVisual.y"
  [attr.width]="barVisual.width"
  [attr.height]="barVisual.height"
  [attr.rx]="barVisual.rx"
  [attr.ry]="barVisual.ry"
  [attr.clip-path]="barVisual.clip"
></svg:rect>
<svg:text
  *ngIf="barVisual.showCount && barVisual.count > 1"
  [attr.x]="barVisual.x + barVisual.width / 2"
  [attr.y]="barVisual.y + (barVisual.height > 20 ? 12 : -2)"
  [attr.fill]="'white'"
  text-anchor="middle"
  font-size="8"
  class="unselectable"
>
  {{ barVisual.count }}
</svg:text>
