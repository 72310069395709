import { scaleBand, ScaleBand, ScaleLinear, scaleLinear } from 'd3';
import { Bar } from '../objects/bar';
import { Tick } from '../objects/tick';
import moment from 'moment';

export interface BarChartData {
  timestamp: string;
  data: {
    [x: string]: {
      value: number;
    };
  };
}

export class BarChart {
  private data: BarChartData[];
  private bars: { [group: string]: Bar[] };
  private groups: string[];
  private width: number;
  private height: number;
  fxScale: ScaleBand<string>;
  private xScale: ScaleBand<string>;
  private yScale: ScaleLinear<number, number>;
  private margins: any = {
    top: 100,
    right: 80,
    bottom: 60,
    left: 80
  };

  private biggestDataPoint = 0;

  constructor() {}

  calculateWidth(): number {
    return this.width - this.margins.left - this.margins.right;
  }

  calculateHeight(): number {
    return this.height - this.margins.top - this.margins.bottom;
  }

  prepareScales(): void {
    const data = this.data.map((d) => d.data);
    const xUniqueValues = new Set<string>();

    Object.values(data).forEach((events) => Object.keys(events).forEach((event) => xUniqueValues.add(event)));

    this.fxScale = scaleBand().range([this.calculateWidth(), 0]).domain(this.groups).paddingInner(0.1);
    this.xScale = scaleBand().range([0, this.fxScale.bandwidth()]).domain(Array.from(xUniqueValues)).padding(0.05);
    this.yScale = scaleLinear().range([this.calculateHeight(), 0]);
  }

  prepareYAxisRange() {
    this.yScale.domain([
      Math.min(0, ...this.data.map((d) => Math.min(...Object.values(d.data).map((d) => d.value)))),
      Math.max(0, ...this.data.map((d) => Math.max(...Object.values(d.data).map((d) => d.value))))
    ]);
  }

  xAxis(): any[] {
    return this.fxScale
      .domain()
      .map(
        (tick: any, i) =>
          new Tick(
            'xaxis',
            i % 2 == 0 ? moment(tick).format('MMM DD') : '',
            'translate(' + this.fxScale(tick) + ', ' + (this.calculateHeight() + 20) + ')',
            { x: 0, dy: 0.32, textAnchor: 'middle' }
          )
      );
  }

  yAxis(): any[] {
    return this.yScale.ticks(5).map(
      (tick: any) =>
        new Tick('yaxis', tick, 'translate(0, ' + this.yScale(tick) + ')', { dx: -5 }, true, {
          x2: this.calculateWidth()
        })
    );
  }

  calculateBarHeight(bar: BarChartData['data']['']) {
    if (!bar) return 0;
    return (bar.value / this.biggestDataPoint) * this.calculateHeight();
  }

  calculateBars() {
    this.bars = {};

    this.data.forEach((barData) => {
      if (moment().diff(moment(barData.timestamp), 'days') >= 29) return;
      const keys = Object.keys(barData.data);

      keys.forEach((key: string) => {
        if (!this.bars[barData.timestamp]) this.bars[barData.timestamp] = [];

        const width = this.xScale.bandwidth();
        const bar = new Bar(barData.timestamp, barData.data[key].value);
        bar.class = key;
        bar.count = barData.data[key].value;
        bar.x = this.xScale(key);
        bar.y = this.yScale(barData.data[key].value);
        bar.width = width;
        bar.height = this.calculateHeight() - this.yScale(barData.data[key].value);
        bar.rx = width / 2;
        bar.ry = width / 2;

        this.bars[barData.timestamp].push(bar);
      });
    });
  }

  update(data: BarChartData[] = [], width?: number, height?: number, margins?: any, groups?: string[]) {
    this.data = data;
    this.groups = groups;

    this.biggestDataPoint = data.reduce<number>((max, current) => {
      const currentMax = Math.max(...Object.values(current.data).map((d) => d.value));
      if (currentMax > max) return currentMax;
      else return max;
    }, 0);

    if (width) {
      this.width = width;
    }
    if (height) {
      this.height = height;
    }
    if (margins) {
      this.margins = margins;
    }

    this.prepareScales();
    this.prepareYAxisRange();
    this.calculateBars();

    return this.bars;
  }

  scaleValue(value: any): any {
    return this.fxScale(value);
  }

  getBiggestDataPoint() {
    return this.biggestDataPoint;
  }
}
