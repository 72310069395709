<div class="row" id="conf-capabilities">
  <div class="col-xs-12">
    <collapsiblebox [show]="expand" [collapsible]="true" (toggle)="toggleExpand()">
      <div header>{{ 'configurations.capabilities.title' | translate }}</div>
      <div container *ngIf="expand">
        <div class="columns">
          <div class="column">
            <div class="offlineText" *ngIf="isOffline === true">
              {{ 'configurations.capabilities.locationIsOffline' | translate }}
            </div>
            <slider-toggle-mobile-aware
              *ngIf="filterItems"
              [options]="filterItems"
              (toggle)="viewSetFilter($event)"
            ></slider-toggle-mobile-aware>
            <ng-container *ngIf="parsedCapabilities && filterType !== 'compressed'">
              <ng-container *ngFor="let capability of parsedCapabilities">
                <div class="row">
                  <div class="key">{{ capability.translate | translate }}</div>
                  <div class="value" [tooltip]="capability.reason | translate" [maxWidth]="400">
                    <ng-container *ngIf="capability.capable">
                      <div class="circle green"></div>
                      <div>{{ 'configurations.capabilities.capable' | translate }}</div>
                    </ng-container>
                    <ng-container *ngIf="!capability.capable">
                      <div class="circle red"></div>
                      <div>{{ 'configurations.capabilities.notCapable' | translate }}</div>
                    </ng-container>
                  </div>
                </div>
              </ng-container>
            </ng-container>
            <div class="compressed" *ngIf="parsedCapabilities && filterType === 'compressed'">
              <ng-container *ngFor="let capability of parsedCapabilities">
                <span
                  [class.capable]="capability.capable"
                  [class.notCapable]="!capability.capable"
                  [tooltip]="capability.reason | translate"
                  [maxWidth]="400"
                >
                  {{ capability.sortStr }}
                </span>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </collapsiblebox>
  </div>
</div>
