<modal class="chat-window" mode="compact" [closeOnClickOutside]="true" (overlayAction)="hideChatDialog.emit()">
  <ng-container title> {{ 'health.networkInformation.modal.supportAssistant' | translate }}</ng-container>
  <ng-container content>
    <ng-container>
      <div #messageRef class="chatMessageContainer" *ngFor="let message of messages$ | async; let i = index">
        <div *ngIf="message.responseType === 'feedback'" class="feedbackDivider"></div>
        <div class="chatBubble">
          <icon *ngIf="message.responseType === 'text'" name="icon-message"></icon>
          <icon
            *ngIf="message.responseType === 'spin' && !message.selectedOption"
            name="icon-spinner"
            class="spinner"
            [class.loading]="message.selectedOption === ''"
          ></icon>
          <icon *ngIf="message.responseType === 'spin' && message.selectedOption !== ''" name="icon-check"></icon>
          <div class="message">{{ message.message }}</div>
        </div>
        <div class="chatControls" *ngIf="hasSelections(message.selections)">
          <ng-container *ngFor="let selectionKey of getObjectKeys(message.selections)">
            <button
              class="chat-select medium"
              (click)="selectButton.emit({ selection: selectionKey, message: message, index: i })"
              [disabled]="message.selectedOption !== ''"
            >
              {{ message.selections[selectionKey] }}
            </button>
          </ng-container>
        </div>
        <div class="right-aligned-controls" *ngIf="message.selectedOption && message.responseType !== 'spin'">
          <button [class.selected]="true" class="chat-select medium" [disabled]="message.selectedOption !== ''">
            {{ message.selections[message.selectedOption] }}
          </button>
        </div>
      </div>
    </ng-container>
  </ng-container>
</modal>
