import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { selectBaseDeviceUrl, selectBaseNodeUrl, selectBaseUrl } from 'src/app/store/customer/customer.selectors';
import { QoeHelper } from '../helpers/qoe.helper';
import {
  I5gQoeMetrics,
  IDeviceOrNodeLiveModeStream,
  IDeviceOrNodeQoeMetrics,
  ILocationQoe,
  INeighborReportCns,
  IQoeMetrics
} from '../interfaces/interface';
import { ApiService } from './api.service';
import { PlumeService } from './plume.service';

@Injectable()
export class QoeService {
  helper: QoeHelper = new QoeHelper();

  constructor(private api: ApiService, private store: Store, private plume: PlumeService) {}

  prepare(data: any = {}): any {
    return {
      busy: data.isBusy ? data.isBusy === 'true' : null,
      lpmRatio: data.lpmRatio ? data.lpmRatio : null,
      qoe:
        data.score || data.usageBasedScore || data.needBasedScore
          ? data.score
            ? parseFloat(data.score)
            : data.isBusy === 'true'
            ? parseFloat(data.usageBasedScore)
            : parseFloat(data.needBasedScore)
          : data.mlo?.score || data.mlo?.usageBasedScore || data.mlo?.needBasedScore
          ? data.mlo?.score
            ? parseFloat(data.mlo?.score)
            : data.isBusy === 'true'
            ? parseFloat(data.mlo?.usageBasedScore)
            : parseFloat(data.mlo?.needBasedScore)
          : null,
      rssi: data.rssi ? parseFloat(data.rssi) : data.snr ? parseFloat(data.snr) - 95 : null,
      mloRssi: data.links?.length ? data.links.map((link) => ({ rssi: link.rssi, freqBand: link.freqBand })) : null,
      snrBasedPhyRate: data.snrBasedPhyRate ? parseFloat(data.snrBasedPhyRate) : null,
      congestion: data.channelUtilization ? parseFloat(data.channelUtilization) : null,
      currentUsage: data.currentUsage ? parseFloat(data.currentUsage) : null,
      potentialThroughput: data.potentialThroughput
        ? parseFloat(data.potentialThroughput)
        : data.mlo?.potentialThroughput
        ? parseFloat(data.mlo?.potentialThroughput)
        : null,
      interference: data.interference ? parseFloat(data.interference) : null,
      rxPrr: data.rxPrr ? parseFloat(data.rxPrr) : null,
      txPrr: data.txPrr ? parseFloat(data.txPrr) : null,
      rxPhyRate: data.rxPhyRate ? parseFloat(data.rxPhyRate) : null,
      txPhyRate: data.txPhyRate ? parseFloat(data.txPhyRate) : null,
      snr: data.snr ? parseFloat(data.snr) : null,
      score: data.score ? parseFloat(data.score) : null,
      nss: data.nss ? parseFloat(data.nss) : false,
      mcs: data.mcs ? parseFloat(data.mcs) : false,
      chWidth: data.chWidth ? parseFloat(data.chWidth) : 0,
      phyMode: data.phyMode ? parseFloat(data.phyMode) : false,
      maxCapabilityBasedPhyRate: data.maxCapabilityBasedPhyRate ? parseFloat(data.maxCapabilityBasedPhyRate) : null,
      phyRateEff:
        data.phyRateEff || data.rxPhyRate || data.maxCapabilityBasedPhyRate
          ? data.phyRateEff
            ? parseFloat(data.phyRateEff)
            : data.rxPhyRate &&
              data.maxCapabilityBasedPhyRate &&
              (data.nss || parseFloat(data.maxCapabilityBasedPhyRate) > 150)
            ? Math.floor((parseFloat(data.rxPhyRate) / parseFloat(data.maxCapabilityBasedPhyRate)) * 100)
            : false
          : null
    };
  }

  lineCharts(data: any = null, mock: any = {}): any {
    return this.helper.lineCharts(data, mock);
  }

  getNodeQoeData(response: any, node: any, callback?: any): any {
    const qoe = this.prepare(response);

    node.rssi = qoe.rssi;

    if (node.health) {
      if (node.health.details) {
        node.health.details = { ...node.health.details, interference: qoe.interference };
      } else {
        node.health.details = { interference: qoe.interference };
      }
    } else {
      node.health = {};
    }

    if (callback) {
      callback();
    }

    return node;
  }

  formatBandwidth(bw: any = null): any {
    // from cloud 1.47 qoe.chWidth is now a string like 40MHz rather than a number, PC has already typed it to a number
    // so checking 20 or above
    if (bw >= 20) {
      return bw;
    }
    return 10 * Math.pow(2, bw + 1);
  }

  getDeviceQoeData(response: any, device: any, callback?: any): any {
    const qoe = this.prepare(response);
    const capabilities = [];

    if (device.capabilities && device.capabilities.radio24) {
      if (device.freqBand && device.freqBand.includes('2.4G') && (qoe.nss || qoe.nss === 0)) {
        const mimo = Math.max(qoe.nss, 1) + 'x' + Math.max(qoe.nss, 1);
        const bw = this.formatBandwidth(qoe.chWidth);
        const protocol = qoe.phyMode && qoe.phyMode === 3 ? 'g' : qoe.mcs >= 8 && qoe.mcs <= 11 ? 'ax' : 'n';

        capabilities.push('2.4GHz, ' + mimo + ' 802.11' + protocol + ', ' + bw + 'MHz');
      } else {
        capabilities.push('2.4GHz');
      }
    }
    if (device.capabilities && device.capabilities.radio50) {
      if (
        device.freqBand &&
        device.freqBand.includes('5G') &&
        (qoe.nss || qoe.nss === 0) &&
        !(qoe.nss === 0 && qoe.mcs === 0 && qoe.chWidth === 0)
      ) {
        const mimo = qoe.nss + 'x' + qoe.nss;
        const bw = this.formatBandwidth(qoe.chWidth);
        const protocol = bw >= 80 ? (qoe.mcs >= 10 && qoe.mcs <= 11 ? 'ax' : 'ac') : 'n';

        capabilities.push('5GHz, ' + mimo + ' 802.11' + protocol + ', ' + bw + 'MHz');
      } else {
        capabilities.push('5GHz');
      }
    }
    if (device.capabilities && device.capabilities.radio60) {
      if (
        device.freqBand &&
        device.freqBand.includes('6G') &&
        (qoe.nss || qoe.nss === 0) &&
        !(qoe.nss === 0 && qoe.mcs === 0 && qoe.chWidth === 0)
      ) {
        const mimo = qoe.nss + 'x' + qoe.nss;
        const bw = this.formatBandwidth(qoe.chWidth);
        const protocol = 'ax';

        capabilities.push('6GHz, ' + mimo + ' 802.11' + protocol + ', ' + bw + 'MHz');
      } else {
        capabilities.push('6GHz');
      }
    }

    device.capabilityString = capabilities;
    device.rssi = qoe.rssi;

    if (device.health) {
      if (device.health.details) {
        device.health.details = { ...device.health.details, interference: qoe.interference };
      } else {
        device.health.details = { interference: qoe.interference };
      }
    } else {
      device.health = {};
    }

    if (callback) {
      callback();
    }

    return device;
  }

  calculateHistory(rssi: any, mode: string, converted: any = null): any[] {
    return this.helper.calculateHistory(rssi, mode, converted);
  }

  convertRssi(rssi: any): any {
    return this.helper.convertRssi(rssi);
  }

  convertBandwidth(bandwidth: any): any {
    return this.helper.convertBandwidth(bandwidth);
  }

  calculateBusyness(bandwidth: any, mode: string): any[] {
    return this.helper.calculateBusyness(bandwidth, mode);
  }

  calculateSteering(steeringData: any, mode: string, typeOfSteering: string): any[] {
    return this.helper.calculateSteering(steeringData, mode, typeOfSteering);
  }

  metrics$(params: { nodeIds?: string[]; deviceIds?: string[] }): Observable<IQoeMetrics> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.post(`${baseUrl}/qoeMetrics`, params, 'reports'))
    );
  }

  deviceMetrics$(mac: string, granularity: string): Observable<IDeviceOrNodeQoeMetrics> {
    return this.store.select(selectBaseDeviceUrl({ mac, isFlexRole: this.plume.isFlexRole() })).pipe(
      take(1),
      switchMap((baseUrl) =>
        this.api.get(`${baseUrl}/qoeMetrics?granularity=${granularity}`, this.plume.isFlexRole() ? 'api' : 'reports')
      )
    );
  }

  getLiveModeState$(): Observable<{ enable: boolean; expiresAt: string; reportingInterval: number }> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.get(`${baseUrl}/qoe/liveMode`, 'reports'))
    );
  }

  setLiveModeState$(enable: boolean, expiresAt?: string, reportingInterval: number = 60): Observable<void> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) =>
        this.api.patch(
          `${baseUrl}/qoe/liveMode`,
          enable
            ? {
                enable,
                expiresAt,
                reportingInterval
              }
            : {
                enable
              },
          'api'
        )
      )
    );
  }

  deviceSuperLiveModeStream$(mac: string): Observable<IDeviceOrNodeLiveModeStream> {
    return this.store.select(selectBaseDeviceUrl({ mac, isFlexRole: this.plume.isFlexRole() })).pipe(
      take(1),
      switchMap((baseUrl) =>
        this.api.get(`${baseUrl}/qoe/superLiveModeStream`, this.plume.isFlexRole() ? 'api' : 'reports')
      )
    );
  }

  deviceLiveModeStream$(mac: string): Observable<IDeviceOrNodeLiveModeStream> {
    return this.store.select(selectBaseDeviceUrl({ mac, isFlexRole: this.plume.isFlexRole() })).pipe(
      take(1),
      switchMap((baseUrl) => this.api.get(`${baseUrl}/qoe/liveModeStream`, this.plume.isFlexRole() ? 'api' : 'reports'))
    );
  }

  nodeMetrics$(nodeId: string, granularity: 'days', limit: number): Observable<IDeviceOrNodeQoeMetrics> {
    return this.store.select(selectBaseNodeUrl({ nodeId })).pipe(
      take(1),
      switchMap((baseUrl) => this.api.get(`${baseUrl}/qoeMetrics?granularity=${granularity}&limit=${limit}`, 'reports'))
    );
  }

  nodeSuperLiveModeStream$(nodeId: string): Observable<IDeviceOrNodeLiveModeStream> {
    return this.store.select(selectBaseNodeUrl({ nodeId })).pipe(
      take(1),
      switchMap((baseUrl) => this.api.get(`${baseUrl}/qoe/superLiveModeStream`, 'reports'))
    );
  }

  nodeLiveModeStream$(nodeId: string): Observable<IDeviceOrNodeLiveModeStream> {
    return this.store.select(selectBaseNodeUrl({ nodeId })).pipe(
      take(1),
      switchMap((baseUrl) => this.api.get(`${baseUrl}/qoe/liveModeStream`, 'reports'))
    );
  }

  locationQoe$(): Observable<ILocationQoe> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) =>
        this.api.get(
          `${baseUrl}/${this.plume.isFlexRole() ? 'flex/' : ''}qoe`,
          this.plume.isFlexRole() ? 'api' : 'reports'
        )
      )
    );
  }

  get5gQoeMetrics$(startTime: string, endTime: string): Observable<I5gQoeMetrics[]> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) =>
        this.api.get(`${baseUrl}/cellular/qoeMetrics?startAt=${startTime}&endAt=${endTime}`, 'reports')
      )
    );
  }

  getNeighborReportCns$(): Observable<INeighborReportCns> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.get(`${baseUrl}/cellular/neighborReportCns`, 'reports'))
    );
  }
}
