import { scaleLinear, scaleTime } from 'd3';
import { Bar } from '../objects/bar';
import { Tick } from '../objects/tick';
import * as moment from 'moment';

export class SpeedtestChart {
  private data: any[];
  private bars: Bar[];
  private width: number;
  private height: number;
  private xScale: any;
  private yScale: any;
  private xRange: any;
  private mode: string;
  private margins: any = {
    top: 100,
    right: 80,
    bottom: 60,
    left: 80
  };

  constructor() {}

  calculateWidth(): number {
    return this.width - this.margins.left - this.margins.right;
  }

  calculateHeight(): number {
    return this.height - this.margins.top - this.margins.bottom;
  }

  getMargins(): any {
    return this.margins;
  }

  prepareScales(): void {
    this.xScale = scaleTime().range([0, this.calculateWidth()]);
    this.yScale = scaleLinear().range([this.calculateHeight(), 0]);
  }

  prepareDomains(): void {
    this.xScale.domain([new Date(this.xRange.start), new Date(this.xRange.end)]);

    if (this.data.length) {
      this.yScale
        .domain([
          Math.min(0, ...this.data.map((d: any) => d.value)),
          Math.max(0, ...this.data.map((d: any) => d.value))
        ])
        .nice(2);
    }
  }

  calculateBars(): void {
    this.bars = this.data.map((bar: any) => {
      const width = this.calculateWidth() / (this.mode === '24h' ? 24 : 56) - 1;
      bar.class = bar.options[0]?.type;
      bar.x = this.xScale(new Date(bar.label)) - width;
      bar.y = this.yScale(bar.value);
      bar.width = width;
      bar.height = this.calculateHeight() - this.yScale(bar.value);
      return bar;
    });
  }

  xAxis(): Tick[] {
    if (this.mode === '24h') {
      return [
        new Tick(
          'xaxis left',
          moment(this.xRange.start).format('LT'),
          'translate(' + this.xScale(new Date(this.xRange.start)) + ',' + (this.calculateHeight() + 20) + ')'
        ),
        new Tick(
          'xaxis left',
          'speedtests.speednode.yesterday',
          'translate(' + this.xScale(new Date(this.xRange.start)) + ',' + (this.calculateHeight() + 20) + ')',
          { dy: 12 }
        ),
        new Tick(
          'xaxis right',
          moment(this.xRange.end).format('LT'),
          'translate(' + this.xScale(new Date(this.xRange.end)) + ',' + (this.calculateHeight() + 20) + ')'
        )
      ];
    } else {
      return [
        new Tick(
          'xaxis left',
          moment(this.xRange.start).format('L'),
          'translate(' + this.xScale(new Date(this.xRange.start)) + ',' + (this.calculateHeight() + 20) + ')'
        ),
        new Tick(
          'xaxis right',
          moment(this.xRange.end).format('L'),
          'translate(' + this.xScale(new Date(this.xRange.end)) + ',' + (this.calculateHeight() + 20) + ')'
        )
      ];
    }
  }

  yAxisGrid(): Tick[] {
    const grid: Tick[] = [];

    grid.push(
      new Tick('yaxis baseline', '', 'translate(0, ' + (this.yScale(0) + 1) + ')', {}, true, {
        x2: this.calculateWidth()
      })
    );

    if (this.data.length) {
      this.yScale.ticks(2).forEach((tick: any) => {
        if (tick) {
          grid.push(
            new Tick('yaxis', '', 'translate(0, ' + (this.yScale(tick) + 1) + ')', {}, true, {
              x2: this.calculateWidth()
            })
          );
        }
      });
    }

    return grid;
  }

  yAxisLabels(): Tick[] {
    const labels: Tick[] = [];

    if (this.data.length) {
      this.yScale.ticks(2).forEach((tick: any) => {
        if (tick) {
          labels.push(new Tick('yaxis', tick + ' Mbps', 'translate(0, ' + this.yScale(tick) + ')'));
        }
      });
    }

    return labels;
  }

  update(data: any[], width?: number, height?: number, margins?: any, xrange?: any): Bar[] {
    this.data = data;

    if (width) {
      this.width = width;
    }
    if (height) {
      this.height = height;
    }
    if (margins) {
      this.margins = margins;
    }
    if (xrange) {
      this.mode = xrange.mode;
      this.xRange = {
        start: moment(xrange.start).startOf('hour'),
        end: moment(xrange.end).startOf('hour')
      };
    }

    this.prepareScales();
    this.prepareDomains();

    this.calculateBars();

    return this.bars;
  }
}
