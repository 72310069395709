import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { map, take, tap } from 'rxjs/operators';
import { DeepReadonly, ILocation, IUpgrade, IVersionMatrices } from 'src/app/lib/interfaces/interface';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { ToastService } from 'src/app/lib/services/toast.service';
import { TroubleshootingService } from 'src/app/lib/services/troubleshooting.service';
import { configReloadData } from 'src/app/store/configview/config.actions';
import { selectPipeLocationOnChange } from 'src/app/store/customer/customer.selectors';
import { selectNodes } from 'src/app/store/polling/polling.selector';

@Component({
  selector: 'firmware',
  templateUrl: './firmware.component.html',
  styleUrls: ['./firmware.component.scss']
})
export class FirmwareComponent implements OnInit, OnChanges, OnDestroy {
  nodesSubscription: any;
  expand: boolean = false;
  firmwareUpgrade$ = this.store.pipe(selectPipeLocationOnChange).pipe(
    tap((location) => {
      this.registerFilter(location);
      this.getMatrixDetails(location);
    }),
    map((location) => location?.firmwareUpgrade)
  );
  locationModels: string[] = [];
  minimumVersions: { model: string; version: string }[] = [];
  allMatrices: IVersionMatrices[] = [];
  matrices: any[] = [];
  showMinimalVersion: boolean = false;
  showMatrixDetails: boolean = false;
  showMatrixSelect: boolean = false;
  matrixDetails: any = {};
  details: any = null;
  selectedVersion: IVersionMatrices = null;
  canUpgradeFirmware: boolean = false;
  inProgress: boolean = true;

  @Input()
  open: boolean = false;

  @Output()
  toggle: any = new EventEmitter();

  @Output()
  filter: any = new EventEmitter();

  @Output()
  clearFilter = new EventEmitter<{ section: string }>();

  @Output()
  openVersionMatrixModal = new EventEmitter<void>();

  constructor(
    private troubleshooting: TroubleshootingService,
    private plume: PlumeService,
    private router: Router,
    private toast: ToastService,
    private mixpanel: MixpanelService,
    private translate: TranslateService,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.store
      .pipe(selectPipeLocationOnChange)
      .pipe(take(1))
      .subscribe((location) => this.init(location));

    this.nodesSubscription = this.store.select(selectNodes).subscribe((nodes) => {
      if (nodes) {
        this.locationModels = nodes.map((node: any) => node.model);
      }
    });
  }

  ngOnChanges(changes: any): void {
    this.expand = changes.open.currentValue;
  }

  init(location: DeepReadonly<ILocation>): void {
    this.getData(false, true);

    this.canUpgradeFirmware = !this.plume.isSomeSupportRole();

    this.troubleshooting.getVersionMatrices$().subscribe((response) => {
      this.allMatrices = response;
      this.getMatrixDetails(location);
    });
  }

  getMatrixDetails(location: DeepReadonly<ILocation>): void {
    if (this.allMatrices.length) {
      this.matrixDetails = this.allMatrices.find(
        (matrix: any) =>
          matrix.versionMatrix ===
          (location.firmwareUpgrade?.activeVersionMatrixId || location.firmwareUpgrade?.pendingVersionMatrixId)
      );
    }
  }

  registerFilter(location: DeepReadonly<ILocation>): void {
    this.clearFilter.emit({ section: 'firmware' });

    if (location?.firmwareUpgrade?.activeVersionMatrixId) {
      this.translate
        .get('configurations.firmware.activeVersionMatrix')
        .subscribe((translated: string) =>
          this.filter.emit({ section: 'firmware', property: 'activeVersionMatrix', translation: translated })
        );
    }

    if (location?.firmwareUpgrade?.pendingVersionMatrixId) {
      this.translate
        .get('configurations.firmware.targetVersionMatrix')
        .subscribe((translated: string) =>
          this.filter.emit({ section: 'firmware', property: 'targetVersionMatrix', translation: translated })
        );
    }

    this.translate
      .get('configurations.firmware.updated')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'firmware', property: 'updated', translation: translated })
      );

    this.translate
      .get('configurations.firmware.selectVersionMatrix')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'firmware', property: 'selectVersionMatrix', translation: translated })
      );

    this.translate
      .get('configurations.firmware.minimumVersions')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'firmware', property: 'minimumVersions', translation: translated })
      );

    this.translate
      .get('configurations.firmware.upgradeFtm')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'firmware', property: 'upgradeFtm', translation: translated })
      );
  }

  getData(poll: boolean = false, init = false): void {
    this.details = null;
    this.minimumVersions = [];

    this.troubleshooting.getFirmware$().subscribe((response) => {
      this.minimumVersions = Object.keys(response.mininumRequiredVersionsForOnboarding).map((model) => ({
        model,
        version: response.mininumRequiredVersionsForOnboarding[model]
      }));
      if (!init) {
        this.store.dispatch(configReloadData());
      }
    });

    this.getLatestState(poll);
  }

  getLatestState(poll: boolean = false): void {
    this.troubleshooting.upgradeReport$().subscribe((response) => {
      if (response.length) {
        const latest = response[0];

        const nodes = latest.finalStatus.map((node) => {
          const init = latest.initStatus.find((n) => n.serialNumber === node.serialNumber);

          return {
            id: node.serialNumber,
            connectionState: node.connectionState,
            initFirmware: init.firmwareVersion,
            initState: init.state,
            finalFirmware: node.firmwareVersion,
            finalState: node.state,
            errorDescription: node.errorDescription
          };
        });

        this.details = {
          status: latest.status,
          target: latest.targetVersionMatrix,
          created: latest.created,
          updated: latest.updated,
          nodes
        };

        const pollKillerStatuses = ['updated', 'completed', 'failed', 'skipped'];

        if (pollKillerStatuses.indexOf(latest.status) < 0 || poll) {
          setTimeout(() => {
            this.getLatestState(false);
          }, 5000);
        } else {
          this.inProgress = false;
        }
      } else {
        if (poll) {
          setTimeout(() => {
            this.getLatestState(false);
          }, 4000);
        }
      }
    });
  }

  clear(downgrade: boolean): void {
    this.troubleshooting.matrixRequest$('clear', true, downgrade).subscribe(
      () => {
        this.toast.success('configurations.firmware.toast.clearMessage', 'configurations.firmware.toast.clearTitle');
        this.mixpanel.storeEvent('LOCATION_FIRMWARE_CLEAR', { LOCATIONID: this.plume.locationid });

        this.showMatrixSelect = false;
        this.selectedVersion = null;
        this.getData();
      },
      (error: any) => {
        this.toast.error('configurations.firmware.toast.errorMessage', 'configurations.firmware.toast.errorTitle');
        this.mixpanel.storeEvent('LOCATION_FIRMWARE_CLEAR_ERROR', {
          LOCATIONID: this.plume.locationid,
          ERROR: error.error.error.message
        });
      }
    );
  }

  disable(downgrade: boolean): void {
    this.troubleshooting.matrixRequest$('disable', true, downgrade).subscribe(
      () => {
        this.toast.success(
          'configurations.firmware.toast.disableMessage',
          'configurations.firmware.toast.disableTitle'
        );
        this.mixpanel.storeEvent('LOCATION_FIRMWARE_DISABLE', { LOCATIONID: this.plume.locationid });
        this.showMatrixSelect = false;
        this.selectedVersion = null;
        this.getData();
      },
      (error: any) => {
        this.toast.error('configurations.firmware.toast.errorMessage', 'configurations.firmware.toast.errorTitle');
        this.mixpanel.storeEvent('LOCATION_FIRMWARE_DISABLE_ERROR', {
          LOCATIONID: this.plume.locationid,
          ERROR: error.error.error.message
        });
      }
    );
  }

  requestUpdate(selectedUpgrade: IUpgrade): void {
    this.selectedVersion = selectedUpgrade.versionMatrix;
    if (this.selectedVersion) {
      this.troubleshooting
        .matrixRequest$(this.selectedVersion.versionMatrix, selectedUpgrade.asap, selectedUpgrade.preventDowngrades)
        .subscribe(() => {
          this.toast.success(
            'configurations.firmware.toast.updateMessage',
            'configurations.firmware.toast.updateTitle'
          );
          this.mixpanel.storeEvent('LOCATION_FIRMWARE_REQUEST_UPDATE', {
            LOCATIONID: this.plume.locationid,
            MATRIX_VERSION: this.selectedVersion.versionMatrix,
            FORCE: selectedUpgrade.asap,
            PREVENT_DOWNGRADE: selectedUpgrade.preventDowngrades
          });

          this.showMatrixSelect = false;
          this.selectedVersion = null;
          this.inProgress = true;

          this.getData(true);
        });
    }
  }

  openModal(modal: 'versionMatrix') {
    switch (modal) {
      case 'versionMatrix':
        this.showMatrixSelect = true;
        this.mixpanel.storeEvent('LOCATION_FIRMWARE_MATRIX_SELECT_MODAL', { LOCATIONID: this.plume.locationid });
        break;
    }
  }

  closeModal(modal: 'versionMatrix') {
    switch (modal) {
      case 'versionMatrix':
        this.showMatrixSelect = false;
        this.selectedVersion = null;
        break;
    }
  }

  toggleExpand(): void {
    this.toggle.emit(!this.expand);

    if (!this.expand) {
      this.mixpanel.storeEvent('CONFIGURATION_FIRMWARE_SCREEN');
    }
  }

  navigateTimelines(): void {
    this.mixpanel.storeEvent('LOCATION_FIRMWARE_FTM_REDIRECT', { LOCATIONID: this.plume.locationid });
    this.router.navigate([
      'customer',
      this.plume.customerid,
      'location',
      this.plume.locationid,
      'timelines',
      'firmware'
    ]);
  }

  ngOnDestroy(): void {
    if (this.nodesSubscription) {
      this.nodesSubscription.unsubscribe();
    }
  }
}
