<div class="clearAlert" *ngIf="alarmCleared" (click)="alarmCleared = false">
  <div class="alertBackground"></div>
  <div class="alertContent">
    <div class="alertTitle">{{ alarmClearMessage || 'health.alarmMessageCleared' | translate }}</div>
    <div class="alertMessage">{{ 'health.alarmMessage' | translate }}</div>
    <div class="alertButton">{{ 'health.alarmOkay' | translate }}</div>
  </div>
</div>

<div class="container-fluid">
  <network (toggle)="updateNetworkperformance()">
    <div class="content">
      <networkstatus></networkstatus>
      <networkperformance
        id="section-init"
        [status]="alerts?.networkstatus"
        [alerts]="alerts?.networkperformance"
        [overallScore]="alerts?.overallScore"
        [selected]="networkSection"
        (section)="networkSelector($event)"
      ></networkperformance>
    </div>
  </network>

  <spacer *ngIf="networkSection">
    <div class="header">
      <div class="title">
        {{ 'health.networkInformation.diagnostics' | translate }}
      </div>
    </div>
  </spacer>

  <div *ngIf="networkSection === 'connection'" id="section-connection">
    <div class="row">
      <div class="col-xs-12">
        <span
          class="title"
          *ngIf="alerts?.networkstatus?.internet?.network || alerts?.networkstatus?.internet?.extenders.length"
        >
          {{ 'health.sectionTitleInternet' | translate }}
        </span>
      </div>
    </div>

    <span *ngIf="alerts?.networkstatus?.internet?.network">
      <internetconnectionalarm
        [alarm]="alerts?.networkstatus?.internet?.network"
        [isGuidedTroubleshootPubnubSubscribed]="isGuidedTroubleshootPubnubSubscribed"
        (openTroubleShoot)="onOpenGuidedTroubleshoot($event)"
      ></internetconnectionalarm>
      <div class="row">
        <div class="col-xs-12">
          <div class="bubble">
            <p>{{ 'health.internetConnectionMessage1' | translate }}</p>
            <p *ngIf="!alerts?.networkstatus?.internet?.network?.hasgateway">
              {{ 'health.internetConnectionMessage2' | translate }}
            </p>
          </div>
        </div>
      </div>
    </span>
    <span *ngIf="alerts?.networkstatus?.internet?.extenders.length && !alerts?.networkstatus?.internet?.network">
      <internetconnectionalarm
        *ngFor="let extender of alerts?.networkstatus?.internet?.extenders; trackBy: trackNetworkExtenders"
        [alarm]="extender"
      >
      </internetconnectionalarm>
      <div class="row">
        <div class="col-xs-12">
          <div class="bubble">
            <p>{{ 'health.internetConnectionMessage3' | translate }}</p>
            <p>{{ 'health.internetConnectionMessage4' | translate }}</p>
          </div>
        </div>
      </div>
    </span>
  </div>

  <div *ngIf="networkSection === 'stability'" id="section-stability">
    <div class="row">
      <div class="col-xs-12">
        <span class="title" *ngIf="alerts?.networkstatus?.stability?.high || alerts?.networkstatus?.stability?.failed">
          {{ 'health.sectionTitleStability' | translate }}
        </span>
      </div>
    </div>
    <stabilityalarm
      *ngIf="alerts?.networkstatus?.stability?.high"
      [alarm]="alerts?.networkstatus?.stability?.high"
    ></stabilityalarm>
    <stabilityalarm
      *ngIf="alerts?.networkstatus?.stability?.failed"
      [alarm]="alerts?.networkstatus?.stability?.failed"
    ></stabilityalarm>
  </div>

  <div *ngIf="networkSection === 'environment'" id="section-environment">
    <congestionalarm
      *ngIf="alerts?.networkstatus?.environment?.highEnvironmentCongestion"
      [alarm]="alerts?.networkstatus?.environment?.highEnvironmentCongestion"
    ></congestionalarm>
    <div class="row" *ngIf="alerts?.networkstatus?.environment?.neighborReports">
      <div class="col-xs-12">
        <span class="title">
          {{ 'health.sectionTitleNeighborReport' | translate }}
        </span>
      </div>
    </div>
    <neighborreportalarm
      *ngIf="alerts?.networkstatus?.environment?.neighborReports"
      [alarm]="alerts?.networkstatus?.environment?.neighborReports"
    ></neighborreportalarm>
  </div>

  <div *ngIf="networkSection === 'speed'" id="section-speed">
    <div
      class="row"
      *ngIf="
        alerts?.networkperformance?.networkspeed.length > 0 ||
        alerts?.networkperformance?.extenderspeed.length > 0 ||
        alerts?.networkperformance?.deviation?.status === true
      "
    >
      <div class="col-xs-12">
        <span class="title">{{ 'health.sectionTitleSpeed' | translate }}</span>
      </div>
    </div>
    <extenderspeedalarm
      *ngFor="let alarm of alerts?.networkperformance?.networkspeed"
      [nodes]="nodes"
      [alarm]="alarm"
      [facade]="appFacadeData"
      [bbe]="lastBBE"
      [alarmType]="'speed'"
      [thresholds]="thresholds"
    ></extenderspeedalarm>

    <extenderspeedalarm
      *ngFor="let alarm of alerts?.networkperformance?.extenderspeed"
      [nodes]="nodes"
      [alarm]="alarm"
      [facade]="appFacadeData"
      [bbe]="lastBBE"
      [alarmType]="'bbe'"
      [thresholds]="thresholds"
    ></extenderspeedalarm>

    <inconsistentspeedalarm
      [alarm]="alerts?.networkperformance?.deviation"
      [nodes]="nodes"
      *ngIf="alerts?.networkperformance?.deviation?.status"
    ></inconsistentspeedalarm>
  </div>

  <div *ngIf="networkSection === 'coverage'" id="section-coverage">
    <div class="row" *ngIf="alerts?.networkperformance?.coverage">
      <div class="col-xs-12">
        <span class="title">{{ 'health.sectionTitleCoverage' | translate }}</span>
      </div>
    </div>
    <coveragealarm *ngIf="alerts?.networkperformance?.coverage"></coveragealarm>
  </div>

  <div *ngIf="networkSection === 'qoe-pods'" id="section-qoe-pods">
    <div class="row" *ngIf="alerts?.extenders && alerts?.extenders.length > 0">
      <div class="col-xs-12">
        <span class="title">{{ 'health.sectionTitleExtenderQoE' | translate }}</span>
      </div>
    </div>
    <extenderhealthalarm
      *ngFor="let extender of alerts?.extenders"
      [nodes]="nodes"
      [extender]="extender"
      [selected]="qoeSection"
      (section)="qoeSelector($event)"
    ></extenderhealthalarm>
    <div class="row" *ngIf="alerts?.raw?.ethernet?.length > 0">
      <div class="col-xs-12">
        <span class="title">{{ 'health.sectionTitlePoorUplink' | translate }}</span>
      </div>
    </div>
    <ethernetuplinkalarm
      [alerts]="alerts?.raw?.ethernet"
      *ngIf="alerts?.raw?.ethernet?.length > 0"
    ></ethernetuplinkalarm>
  </div>

  <div *ngIf="networkSection === 'qoe-devices'" id="section-qoe-devices">
    <div class="row" *ngIf="alerts?.devices && alerts?.devices.length > 0">
      <div class="col-xs-12">
        <span class="title">{{ 'health.sectionTitleDeviceQoE' | translate }}</span>
      </div>
    </div>
    <devicehealthalarm
      *ngFor="let device of alerts?.devices"
      [nodes]="nodes"
      [device]="device"
      [selected]="qoeSection"
      (section)="qoeSelector($event)"
    ></devicehealthalarm>
    <div class="row" *ngIf="alerts?.devices.length">
      <div class="col-xs-12">
        <div class="bubble">
          <p>{{ 'health.deviceAlarmMessage1' | translate }}</p>
          <p>{{ 'health.deviceAlarmMessage2' | translate }}</p>
          <p>{{ 'health.deviceAlarmMessage3' | translate }}</p>
          <p>{{ 'health.deviceAlarmMessage4' | translate }}</p>
        </div>
      </div>
    </div>
  </div>
  <location-online-state [locationId]="locationId" *ngIf="permissions?.uiFeatures.alarmHistory"></location-online-state>
  <wanusage [locationid]="locationId" *ngIf="permissions?.uiFeatures.wanSaturation && showWANSaturation"></wanusage>

  <collapsiblebox
    class="congestionNodeSection"
    mode="bare"
    [collapsible]="true"
    toggleSide="right"
    [scrollOnOpen]="true"
    *ngIf="alerts?.raw?.congestionNonEmpty?.length > 0"
  >
    <div class="title" header>
      {{ 'health.congestionNodeSectionTitle' | translate }}
    </div>

    <div class="content" container>
      <congestionalarm [bare]="true" [alarm]="alerts.raw.congestionNonEmpty"></congestionalarm></div
  ></collapsiblebox>
  <spacer></spacer>
  <modal mode="compact" [closeOnClickOutside]="true" *ngIf="show.guidedTroublshootingLanding">
    <ng-container title>{{ 'health.networkInformation.modal.supportAssistant' | translate }}</ng-container>
    <ng-container content>
      <p>{{ 'health.networkInformation.modal.networkOffline' | translate }}</p>
    </ng-container>
    <ng-container controls>
      <button class="tertiary medium light" (click)="goToDiagnostics()">
        {{ 'health.networkInformation.modal.viewDiagnostics' | translate }}
      </button>
      <button class="super-primary medium" (click)="openGuidedTroubleshoot()">
        {{ 'health.networkInformation.modal.beginTroubleshooting' | translate }}
      </button>
    </ng-container>
  </modal>

  <chatWindow
    *ngIf="show.guidedTroubleshooting"
    (overlayAction)="hideChatDialog()"
    (selectButton)="selectButton($event)"
    (hideChatDialog)="hideChatDialog()"
    [messages$]="messages$"
  ></chatWindow>

  <chatWindow
    *ngIf="show.guidedTroubleshootingQoe"
    (overlayAction)="hideChatDialog()"
    (selectButton)="selectButtonFake($event)"
    (hideChatDialog)="hideChatDialog()"
    [messages$]="messages$"
  ></chatWindow>
</div>
