import { IHelpItems } from '../help-items';

export const topology: IHelpItems = {
  general: {
    options: {
      locationOnlineNeeded: true
    },
    steps: [
      {
        targetElement: '.pod.gateway',
        content: 'help.topology.general.gateway',
        options: {
          expectMovement: true,
          action: {
            type: 'function',
            function: async () => {
              if (document.querySelector('#realtime-topology')) {
                let modalContent = document.querySelector<HTMLDivElement>('#toolmodal-realtimeUsage-content');
                if (!modalContent) document.querySelector<HTMLDivElement>('#toolbar-realtimeUsage')?.click();

                await new Promise((r) => setTimeout(r, 100));

                const toggle = document.querySelector<HTMLLabelElement>('[for="toggleRealtimeUsage"]');
                if (toggle?.classList.contains('checked')) toggle.click();

                modalContent = document.querySelector<HTMLDivElement>('#toolmodal-realtimeUsage-content');
                if (modalContent) document.querySelector<HTMLDivElement>('#toolbar-realtimeUsage')?.click();

                await new Promise((r) => setTimeout(r, 1000));
              }
            }
          }
        }
      },
      {
        targetElement: '.radios',
        content: 'help.topology.general.radios',
        options: {
          expectMovement: true
        }
      },
      {
        targetElement: '#toolmodal-views-content',
        content: 'help.topology.general.view',
        options: {
          action: {
            type: 'click',
            targetElement: '#toolbar-views'
          },
          exitAction: {
            type: 'click',
            targetElement: '#toolbar-views'
          }
        }
      },
      {
        targetElement: '#toolmodal-overlays-content',
        content: 'help.topology.general.overlay',
        options: {
          action: {
            type: 'click',
            targetElement: '#toolbar-overlays'
          },
          exitAction: {
            type: 'click',
            targetElement: '#toolbar-overlays'
          }
        }
      },
      {
        targetElement: '#toolmodal-realtimeUsage-content',
        content: 'help.topology.realtimeUsage.toggle',
        options: {
          action: {
            type: 'click',
            targetElement: '#toolbar-realtimeUsage'
          },
          exitAction: {
            type: 'function',
            function: async (action) => {
              if (action === 'previous') document.querySelector<HTMLElement>('#toolbar-realtimeUsage')?.click();
            }
          }
        }
      },
      {
        targetElement: '#realtime-topology',
        content: 'help.topology.realtimeUsage.chart',
        options: {
          action: {
            type: 'click',
            targetElement: '#toolmodal-realtimeUsage-content .toggle'
          },
          exitAction: {
            type: 'function',
            function: async (action) => {
              document.querySelector<HTMLElement>('#toolmodal-realtimeUsage-content .toggle')?.click();
              if (action !== 'previous') document.querySelector<HTMLElement>('#toolbar-realtimeUsage')?.click();
            }
          }
        }
      }
    ]
  },
  realtimeUsage: {
    steps: [
      {
        targetElement: '#toolmodal-realtimeUsage-content',
        content: 'help.topology.realtimeUsage.toggle',
        options: {
          action: {
            type: 'click',
            targetElement: '#toolbar-realtimeUsage'
          },
          exitAction: {
            type: 'function',
            function: async (action) => {
              if (action === 'finish') document.querySelector<HTMLElement>('#toolbar-realtimeUsage')?.click();
            }
          }
        }
      },
      {
        targetElement: '#realtime-topology',
        content: 'help.topology.realtimeUsage.chart',
        options: {
          action: {
            type: 'click',
            targetElement: '#toolmodal-realtimeUsage-content .toggle'
          },
          exitAction: {
            type: 'function',
            function: async (action) => {
              if (action === 'previous') {
                document.querySelector<HTMLElement>('#toolmodal-realtimeUsage-content .toggle')?.click();
              }
            }
          }
        }
      },
      {
        targetElement: '.traffic.ng-star-inserted',
        content: 'help.topology.realtimeUsage.traffic',
        options: {
          exitAction: {
            type: 'function',
            function: async (action) => {
              if (action === 'finish') {
                document.querySelector<HTMLElement>('#toolmodal-realtimeUsage-content .toggle')?.click();
                document.querySelector<HTMLElement>('#toolbar-realtimeUsage')?.click();
              }
            }
          }
        }
      }
    ]
  }
};
