import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { IUpgrade, IVersionMatrices, SlideTogglerItems } from 'src/app/lib/interfaces/interface';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { ToastService } from 'src/app/lib/services/toast.service';

@Component({
  selector: 'version-matrix-modal',
  templateUrl: './version-matrix-modal.component.html',
  styleUrls: ['./version-matrix-modal.component.scss']
})
export class VersionMatrixModalComponent implements OnInit {
  @Input()
  allMatrices: IVersionMatrices[] = [];

  @Input()
  locationModels: string[] = [];

  @Input()
  matrixDetails?: IVersionMatrices;

  @Input()
  singleLocation: boolean;

  @Output()
  requestUpdate = new EventEmitter<IUpgrade>();

  @Output()
  clear = new EventEmitter<boolean>();

  @Output()
  disable = new EventEmitter<boolean>();

  @Output()
  closeVersionMatrixModal = new EventEmitter<void>();

  showMatrixSelect: boolean = false;
  search: FormControl = new FormControl();
  filterLocationSpecificVm: boolean = true;
  filterIncludeModels: boolean = false;
  filterOnboardingVm: boolean = false;
  selectedVersion: IVersionMatrices = null;
  selectedUpgrade: IUpgrade = null;
  matrices: IVersionMatrices[] = [];

  versionDetails: IVersionMatrices = null;
  downgrade: boolean = false;
  downgradeItems: SlideTogglerItems<boolean> = [];
  asap: boolean = true;
  asapItems: SlideTogglerItems<boolean> = [];

  constructor(
    private plume: PlumeService,
    private toast: ToastService,
    private mixpanel: MixpanelService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.filterLocationSpecificVm = this.singleLocation;
    this.initTogglers();
    this.filterSearch();
  }

  filterLocationSpecificVmToggle(): void {
    this.filterLocationSpecificVm = !this.filterLocationSpecificVm;
    this.selectedVersion = null;
    this.selectedUpgrade = null;
    this.filterSearch();
  }
  filterSearch(): void {
    let matrices = [];

    if (this.search.value && this.search.value.length) {
      matrices = this.allMatrices.filter((matrice: any) => {
        if (matrice.versionMatrix.toLowerCase().includes(this.search.value.toLowerCase())) {
          return true;
        }

        if (this.filterIncludeModels) {
          for (const model of matrice.models) {
            if (model.firmwareVersion.toLowerCase().includes(this.search.value.toLowerCase())) {
              return true;
            }
          }
        }

        return false;
      });
    } else {
      matrices = JSON.parse(JSON.stringify(this.allMatrices));
    }

    if (!this.filterLocationSpecificVm) {
      this.matrices = matrices;
    } else if (this.locationModels.length) {
      this.matrices = matrices.filter((matrice: any) => {
        let add = false;

        for (const models of matrice.models) {
          if (this.locationModels.indexOf(models.model) > -1) {
            add = true;
            break;
          }
        }

        return add;
      });
    } else {
      this.matrices = [];
    }

    if (this.filterOnboardingVm) {
      this.matrices = this.matrices.filter(
        (matrixElement: IVersionMatrices) =>
          matrixElement.minReqdVerForOnboardingConfigIds.length > 0 ||
          matrixElement.useForAutoUpgradeDuringOnboardingConfigIds.length > 0
      );
    }
  }

  toggleDowngrade(value: boolean): void {
    this.downgrade = value;
    this.initTogglers();
  }

  initTogglers(): void {
    this.downgradeItems = [
      { value: true, translation: 'configurations.firmware.preventDowngrades', selected: this.downgrade },
      { value: false, translation: 'configurations.firmware.enableDowngrades', selected: !this.downgrade }
    ];

    this.asapItems = [
      { value: true, translation: 'configurations.firmware.now', selected: this.asap },
      { value: false, translation: 'configurations.firmware.later', selected: !this.asap }
    ];
  }

  filterIncludeModelsToggle(): void {
    this.filterIncludeModels = !this.filterIncludeModels;
    this.filterSearch();
  }

  filterOnboardingVmToggle(): void {
    this.filterOnboardingVm = !this.filterOnboardingVm;
    this.selectedVersion = null;
    this.selectedUpgrade = null;
    this.filterSearch();
  }

  selectThisVersion(version: IVersionMatrices) {
    this.versionDetails = version;
    this.selectedVersion = version;
  }

  createUpgrade() {
    this.selectedUpgrade = { versionMatrix: this.selectedVersion, asap: this.asap, preventDowngrades: this.downgrade };
    this.requestUpdate.emit(this.selectedUpgrade);
  }

  toggleAsap(value: boolean): void {
    this.asap = value;
    this.initTogglers();
  }

  matricesTrack(index: number, item: { versionMatrix: string }): string {
    return item.versionMatrix;
  }

  closeModal() {
    this.search.reset();
    this.closeVersionMatrixModal.emit();
  }
}
