import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IDevice } from 'src/app/lib/interfaces/interface';

@Component({
  selector: 'ohp-details-modal',
  templateUrl: './ohp-details-modal.component.html',
  styleUrls: ['./ohp-details-modal.component.scss']
})
export class OhpDetailsModalComponent implements OnInit {
  @Input()
  device: IDevice = null;

  @Output()
  closeOhpDetailsModal = new EventEmitter<void>();

  ohpDetails: Record<string, string>;
  ohpNotificationDetails: Record<string, string>;
  hasOhpDetails: boolean = false;
  hasOhpNotificationDetails: boolean = false;

  ngOnInit(): void {
    this.ohpDetails = {};
    const ohp = this.device.ohp;
    const ohpNotifications = this.device.ohp.notifications;

    if (ohp.uuid) this.ohpDetails.uuid = ohp.uuid;
    if (ohp.disableMobilizeSdk !== undefined) this.ohpDetails.disableMobilizeSdk = String(ohp.disableMobilizeSdk);
    if (ohp.userProtected) this.ohpDetails.userProtected = ohp.userProtected.state;
    if (ohp.protectionState) this.ohpDetails.protectionState = ohp.protectionState.type;

    if (Object.keys(this.ohpDetails).length > 0) this.hasOhpDetails = true;

    if (!ohpNotifications) return;
    this.hasOhpNotificationDetails = true;
    this.ohpNotificationDetails = {};
    if (ohpNotifications.deviceLeavesHome !== undefined)
      this.ohpNotificationDetails.deviceLeavesHome = String(ohpNotifications.deviceLeavesHome);
    if (ohpNotifications.deviceReturnsHome !== undefined)
      this.ohpNotificationDetails.deviceReturnsHome = String(ohpNotifications.deviceReturnsHome);
    if (ohpNotifications.protectionStateChangedNotificationsEnabled !== undefined)
      this.ohpNotificationDetails.protectionStateChangedNotificationsEnabled = String(
        ohpNotifications.protectionStateChangedNotificationsEnabled
      );
  }

  closeModal() {
    this.closeOhpDetailsModal.emit();
  }
}
