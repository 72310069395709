import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { map } from 'rxjs/operators';
import { BarChartData } from 'src/app/lib/d3/models/charts/bar.chart';
import { GeneralHelper } from 'src/app/lib/helpers/general.helper';
import { DeepPartial, IDevice, ISecurityPolicy, ISecurityPolicyEventStats } from 'src/app/lib/interfaces/interface';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { ModalService } from 'src/app/lib/services/modal.service';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { SecurityPolicyService } from 'src/app/lib/services/security-policy.service';
import { ToastService } from 'src/app/lib/services/toast.service';
import { selectCapabilities } from 'src/app/store/customer/capabilities.selector';
import {
  selectIsFlexLocation,
  selectIsHomePass,
  selectPipeLocationOnChange
} from 'src/app/store/customer/customer.selectors';
import { selectDevices, selectLocationInternet } from 'src/app/store/polling/polling.selector';

@Component({
  templateUrl: './security.component.html',
  styleUrls: ['./security.component.scss']
})
export class SecurityComponent implements OnInit, OnDestroy {
  policySubscription: any;
  subscriptions: any[] = [];

  onboarded: string = null;
  hasSecurity$ = this.store
    .select(selectCapabilities)
    .pipe(map((capability) => capability?.dnsCategoryBlocking?.capable || capability?.security?.capable));
  togglerMessage: string = 'aisecurity.enableAccess';
  loading: boolean = false;
  deviceAssignedToPerson: boolean = false;
  securityToken: string = null;
  securityPolicy: ISecurityPolicy & { device?: boolean } = null;
  securityEvents: any = null;
  responseEvents: any = null;

  devices: IDevice[];
  ohpDevices: (IDevice & { hasEvents?: boolean })[];

  ohpEvents: ISecurityPolicyEventStats['dailyStats'] = null;
  ohpChartExpanded: boolean = false;
  ohpChartData: BarChartData[] = [];
  ohpSelectedDevice: IDevice = null;

  timer: any = {
    handler: null,
    mins: null,
    secs: null,
    show: false
  };

  selector = {
    loading: true,
    current: 'aisecurity.allDevices' as string,
    mac: null,
    list: [] as IDevice[]
  };

  anomalyDeviceSelector = {
    mac: '',
    dnsBlockCountLoading: false,
    dnsBlockCount: 0,
    current: 'aisecurity.selectDevice'
  };

  filterEnabled: boolean = false;
  filterType: string = null;
  dayView: any = null;

  secureAndProtectItems: any[] = [];
  iotProtectItems: any[] = [];
  adBlockingItems: any[] = [];
  adultAndSensitiveItems: any[] = [];
  workAppropriateItems: any[] = [];
  teenagersItems: any[] = [];
  kidsItems: any[] = [];

  location$ = this.store.pipe(selectPipeLocationOnChange);
  isFlexLocation$ = this.store.select(selectIsFlexLocation);
  isHomePass$ = this.store.select(selectIsHomePass);

  whitelistModal: boolean = false;
  blacklistModal: boolean = false;
  demoAnomalyModal: boolean = false;
  loadingDomains: boolean = false;
  domainFormControl: FormControl = new FormControl('');
  domainList: any[] = [];

  addressType: 'fqdn' | 'ipv4' | 'ipv6' = 'fqdn';

  addressTypes: any = [
    { value: 'fqdn', translation: 'aisecurity.fqdnType', selected: this.addressType === 'fqdn' ? true : false },
    { value: 'ipv4', translation: 'aisecurity.ipv4Type', selected: this.addressType === 'ipv4' ? true : false },
    { value: 'ipv6', translation: 'aisecurity.ipv6Type', selected: this.addressType === 'ipv6' ? true : false }
  ];

  helper: GeneralHelper = new GeneralHelper();

  constructor(
    public plume: PlumeService,
    private mixpanel: MixpanelService,
    private toast: ToastService,
    private store: Store,
    private securityPolicyService: SecurityPolicyService,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.mixpanel.storeEvent('AI_SECURITY_SCREEN');

    this.init();

    this.subscriptions.push(
      this.store.select(selectDevices).subscribe((devices) => {
        this.selector.loading = false;
        this.devices = devices;
        this.ohpDevices = devices?.filter((device) => device.ohp?.capable && device.ohp?.uuid);
        this.setOhpDeviceEvents();
        this.selector.list = devices;
      })
    );

    this.subscriptions.push(
      this.store.select(selectLocationInternet).subscribe((response: any) => {
        if (response) {
          this.isOnboarded(response);
        }
      })
    );
  }

  init(): void {
    this.selector.loading = true;
    this.selector.current = 'aisecurity.allDevices';
    this.selector.mac = null;
  }

  removeDomain(domain: any): void {
    if (!this.deviceAssignedToPerson) {
      this.securityPolicyService
        .removeDomain$(this.whitelistModal ? 'whitelist' : 'blacklist', domain.value, this.selector.mac)
        .subscribe(() => {
          if (this.whitelistModal) {
            const index = this.securityPolicy.websites.whitelisted.indexOf(domain);
            this.securityPolicy.websites.whitelisted.splice(index, 1);
          } else {
            const index = this.securityPolicy.websites.blacklisted.indexOf(domain);
            this.securityPolicy.websites.blacklisted.splice(index, 1);
          }
        });
    }
  }

  sanitizeDomain(domain: string): string {
    domain = domain?.trim() || '';
    // remove www. from the URL provided
    const domainRegEx = new RegExp(/^https?:\/\/www\.|^www\./, 'i');

    if (this.addressType === 'ipv4' || this.addressType === 'ipv6') {
      return domain;
    } else {
      return domainRegEx.test(domain) ? domain.split(/\.(.+)/)[1] : domain;
    }
  }

  validateAddress(address: string, websiteType: string): boolean {
    if (websiteType === 'ipv4' && new RegExp(this.helper.getRegexValidation('ipv4')).exec(address)) {
      return true;
    }
    if (websiteType === 'ipv6' && new RegExp(this.helper.getRegexValidation('ipv6')).exec(address)) {
      return true;
    }
    if (websiteType === 'fqdn' && new RegExp(this.helper.getRegexValidation('domain')).exec(address)) {
      return true;
    }
    return false;
  }

  validateDuplicates(address: string): boolean {
    if (this.whitelistModal) {
      return this.securityPolicy.websites.blacklisted.find(
        (adr: any) => adr.value.toLowerCase() === address.toLowerCase()
      )
        ? false
        : true;
    } else {
      return this.securityPolicy.websites.whitelisted.find(
        (adr: any) => adr.value.toLowerCase() === address.toLowerCase()
      )
        ? false
        : true;
    }
  }

  addDomain(): void {
    if (!this.deviceAssignedToPerson) {
      if (this.validateDuplicates(this.sanitizeDomain(this.domainFormControl.value)) === true) {
        if (this.validateAddress(this.sanitizeDomain(this.domainFormControl.value), this.addressType) === true) {
          this.setDomain();
        } else {
          this.loadingDomains = false;

          if (this.addressType === 'ipv4') {
            this.toast.error(
              'aisecurity.toastIpv4Msg',
              this.whitelistModal ? 'aisecurity.whitelistToastErrorTitle' : 'aisecurity.blacklistToastErrorTitle'
            );
          }
          if (this.addressType === 'ipv6') {
            this.toast.error(
              'aisecurity.toastIpv6Msg',
              this.whitelistModal ? 'aisecurity.whitelistToastErrorTitle' : 'aisecurity.blacklistToastErrorTitle'
            );
          }
          if (this.addressType === 'fqdn') {
            this.toast.error(
              'aisecurity.toastDnsMsg',
              this.whitelistModal ? 'aisecurity.whitelistToastErrorTitle' : 'aisecurity.blacklistToastErrorTitle'
            );
          }
        }
      } else {
        this.loadingDomains = false;
        this.toast.error(
          'aisecurity.toastDuplicateMsg',
          this.whitelistModal ? 'aisecurity.whitelistToastErrorTitle' : 'aisecurity.blacklistToastErrorTitle'
        );
      }
    }
  }

  setDomain(): void {
    this.mixpanel.storeEvent('DOMAIN_ADD_' + (this.whitelistModal ? 'WHITELIST' : 'BLACKLIST'));
    this.loadingDomains = true;
    this.securityPolicyService
      .addDomain$(
        this.whitelistModal ? 'whitelist' : 'blacklist',
        this.sanitizeDomain(this.domainFormControl.value),
        this.addressType,
        this.selector.mac
      )
      .subscribe(
        (response) => {
          this.loadingDomains = false;
          this.domainFormControl.setValue('');
          this.securityPolicy = response;

          const securityPolicyWebsites = this.securityPolicy.websites;
          this.domainList = this.whitelistModal
            ? securityPolicyWebsites.whitelisted
            : securityPolicyWebsites.blacklisted;

          this.mixpanel.storeEvent(this.whitelistModal ? 'ADD_WHITELIST_DOMAIN' : 'ADD_BLACKLIST_DOMAIN');
        },
        (error: any) => {
          // fallback error message if server fails post validation
          this.loadingDomains = false;
          this.toast.error(
            error.error.error.message,
            this.whitelistModal ? 'aisecurity.whitelistToastErrorTitle' : 'aisecurity.blacklistToastErrorTitle'
          );
        }
      );
  }

  updateList(isWhitelist: boolean): void {
    const securityPolicyWebsites = this.securityPolicy.websites;
    this.domainList = isWhitelist ? securityPolicyWebsites.whitelisted : securityPolicyWebsites.blacklisted;
  }

  openWBModal(isWhitelist: boolean): void {
    this.whitelistModal = isWhitelist;
    this.blacklistModal = !isWhitelist;

    this.updateList(isWhitelist);
  }

  closeModal(): void {
    this.domainFormControl.setValue('');
    this.whitelistModal = false;
    this.blacklistModal = false;
  }

  openDemoAnomalyModal(): void {
    this.demoAnomalyModal = true;
  }

  closeDemoAnomalyModal(): void {
    this.domainFormControl.reset();
    this.demoAnomalyModal = false;
    this.anomalyDeviceSelector = {
      current: 'aisecurity.selectDevice',
      mac: '',
      dnsBlockCount: 0,
      dnsBlockCountLoading: false
    };
  }

  triggerAnomaly(): void {
    this.mixpanel.storeEvent('TRIGGER_DNS_ANOMALY', { fqdn: this.domainFormControl.value });
    const fqdn = this.sanitizeDomain(this.domainFormControl.value);
    if (!this.validateAddress(fqdn, 'fqdn')) {
      this.toast.error('aisecurity.toastDnsMsg', 'aisecurity.demoAnomalyDetection.title');
      return;
    }
    if (this.anomalyDeviceSelector.current === 'aisecurity.selectDevice') {
      this.toast.error('aisecurity.demoAnomalyDetection.pleaseSelectDevice', 'aisecurity.demoAnomalyDetection.title');
      return;
    }

    this.securityPolicyService.triggerAnomaly$(this.anomalyDeviceSelector.mac, fqdn).subscribe(
      (response) => {
        if (response.result === 'ignored') {
          this.toast.error(response.reason, 'aisecurity.demoAnomalyDetection.errorTitle');
        } else {
          this.domainFormControl.reset();
          this.getAnomalyBlackListCount();
        }
      },
      (error: any) => {
        this.toast.error(error.error.error.message, 'aisecurity.demoAnomalyDetection.title');
      }
    );
  }

  rollBackAnomaly(): void {
    this.mixpanel.storeEvent('ROLLBACK_DNS_ANOMALY');

    this.securityPolicyService.rollBackAnomaly$(this.anomalyDeviceSelector.mac).subscribe(
      () => {
        this.getAnomalyBlackListCount();
      },
      (error: any) => {
        this.toast.error(error.error.error.message, 'aisecurity.demoAnomalyDetection.title');
      }
    );
  }

  isOnboarded(response: any): void {
    try {
      const permissions = this.plume.getPermissions();

      if (permissions.uiFeatures.overrideOnboarded) {
        this.onboarded = 'complete';
      } else {
        if (['OnboardingComplete', 'PodsAdded'].includes(response.summary.onboardingStatus)) {
          this.onboarded = 'complete';
        } else {
          this.onboarded = 'uncomplete';
        }
      }
    } catch (err) {
      this.onboarded = 'uncomplete';
    }
  }

  selectDevice(device: any = null): void {
    const oldCurrent = this.selector.current;

    this.securityEvents = null;

    this.mixpanel.storeEvent('AI_SECURITY_SELECT_DEVICE', { DEVICE_MAC: device ? device.mac : 'All devices' });

    if (device !== 'aisecurity.allDevices' && device !== 'aisecurity.unassignedDevices') {
      this.selector.current = device.name;
      this.selector.mac = device.mac;
      this.deviceAssignedToPerson = device.personId || device.groupId ? true : false;
    } else {
      this.selector.current = device;
      this.selector.mac = null;
      this.deviceAssignedToPerson = false;

      if (this.securityPolicy) {
        this.securityPolicy['device'] = false;
      }
    }

    if (oldCurrent === 'aisecurity.unassignedDevices' || this.selector.current === 'aisecurity.unassignedDevices') {
      this.responseEvents = [];

      this.getSecurityEvents(new Date().toISOString(), () => {
        this.sortEvents();
        this.getSecurityPolicy();
      });
    } else {
      this.sortEvents();
      this.getSecurityPolicy();
    }
  }

  selectAnomalyDetectionDevice(device: any): void {
    this.anomalyDeviceSelector.current = device.name;
    this.anomalyDeviceSelector.mac = device.mac;
    this.getAnomalyBlackListCount();
  }

  countdown(date: string, token: string): void {
    this.timer.handler = setInterval(() => {
      const diff = new Date(date).getTime() - Date.now();

      if (!this.securityToken) {
        this.loading = false;
        this.togglerMessage = 'aisecurity.accessGranted';
        this.securityToken = token;
        this.responseEvents = [];
        this.getSecurityPolicy();
        this.getSecurityEvents(new Date().toISOString(), () => {
          this.sortEvents();
        });
      }

      if (diff >= 0) {
        this.timer.mins = Math.floor((diff % (60 * 60 * 1000)) / (60 * 1000));
        this.timer.secs = Math.floor((diff % (60 * 1000)) / 1000);
        this.timer.show = true;
      } else {
        this.unsubscribeTimer();
      }
    }, 1000);
  }

  filter(event: any): void {
    this.filterEnabled = event.mode;
    this.filterType = event.type;

    this.mixpanel.storeEvent('AI_SECURITY_FILTER', { FILTER_BY: event.mode ? event.type : 'No filter' });

    this.sortEvents();
  }

  changeView(event: any): void {
    this.dayView = event;

    this.mixpanel.storeEvent('AI_SECURITY_CHANGE_VIEW', { VIEW: event ? event.text : 'Month' });

    this.sortEvents();
  }

  sortEvents(): void {
    let events = [];

    if (this.filterEnabled && this.filterType) {
      switch (this.filterType) {
        case 'iotProtect':
        case 'secureAndProtect':
        case 'adBlocking':
          if (this.dayView) {
            events = [
              ...this.responseEvents.filter((event: any) =>
                moment(new Date(event.createdAt).getTime()).startOf('day').valueOf() === this.dayView.timestamp
                  ? event.eventType.toUpperCase().indexOf(this.filterType.toUpperCase()) >= 0
                    ? true
                    : false
                  : false
              )
            ];
          } else {
            events = this.responseEvents.filter((event: any) =>
              event.eventType.toUpperCase().indexOf(this.filterType.toUpperCase()) >= 0 ? true : false
            );
          }
          break;

        case 'content':
          if (this.dayView) {
            events = [
              ...this.responseEvents.filter((event: any) =>
                moment(new Date(event.createdAt).getTime()).startOf('day').valueOf() === this.dayView.timestamp
                  ? event.eventType.toUpperCase().indexOf('IOTPROTECT') < 0 &&
                    event.eventType.toUpperCase().indexOf('SECUREANDPROTECT') < 0 &&
                    event.eventType.toUpperCase().indexOf('ADBLOCKING') < 0
                    ? true
                    : false
                  : false
              )
            ];
          } else {
            events = this.responseEvents.filter((event: any) =>
              event.eventType.toUpperCase().indexOf('IOTPROTECT') < 0 &&
              event.eventType.toUpperCase().indexOf('SECUREANDPROTECT') < 0 &&
              event.eventType.toUpperCase().indexOf('ADBLOCKING') < 0
                ? true
                : false
            );
          }
          break;
        default:
          if (this.dayView) {
            events = [
              ...this.responseEvents.filter((event: any) =>
                moment(new Date(event.createdAt).getTime()).startOf('day').valueOf() === this.dayView.timestamp
                  ? true
                  : false
              )
            ];
          } else {
            events = [...this.responseEvents];
          }
      }
    } else {
      if (this.dayView) {
        events = [
          ...this.responseEvents.filter((event: any) =>
            moment(new Date(event.createdAt).getTime()).startOf('day').valueOf() === this.dayView.timestamp
              ? true
              : false
          )
        ];
      } else {
        events = [...this.responseEvents];
      }
    }

    if (!this.selector.mac || !this.responseEvents.length) {
      this.securityEvents = events;
    } else {
      this.securityEvents = events.filter((event: any) => event.eventData.mac === this.selector.mac);
    }
  }

  getSecurityToken(): void {
    if (!this.loading) {
      if (!this.securityToken) {
        this.init();
        this.loading = true;
        this.togglerMessage = 'aisecurity.loading';
        this.securityPolicyService.securityToken$().subscribe((response) => {
          this.mixpanel.storeEvent('AI_SECURITY_REQUEST_TOKEN');
          this.countdown(response.expireAt, response.id);
        });
      } else {
        this.unsubscribeTimer();
      }
    }
  }

  getSecurityPolicy(): void {
    if (this.policySubscription) {
      this.policySubscription.unsubscribe();
    }

    this.loadingDomains = true;

    this.policySubscription = this.securityPolicyService
      .securityPolicy$(
        this.selector.current === 'aisecurity.allDevices'
          ? 'all'
          : this.selector.current === 'aisecurity.unassignedDevices'
          ? 'unassigned'
          : this.selector.mac,
        this.securityToken
      )
      .subscribe(
        (response) => {
          this.securityPolicy = response;
          this.securityPolicy['device'] = !!this.selector.mac;
          this.securityPolicy.content.forEach((content: string) => (this.securityPolicy[content] = true));
          this.initTogglers();
          this.updateList(this.whitelistModal);
          this.loadingDomains = false;
        },
        (error: any) => {
          if (this.selector.current === 'aisecurity.unassignedDevices' && error.status === 404) {
            this.toast.warning('aisecurity.unassignedDevicesMsg', 'aisecurity.unassignedDevicesTitle');
            this.selectDevice('aisecurity.allDevices');
          }
        }
      );
  }

  getAnomalyBlackListCount(): void {
    this.anomalyDeviceSelector.dnsBlockCountLoading = true;
    this.securityPolicyService
      .securityPolicy$(this.anomalyDeviceSelector.mac, this.securityToken)
      .subscribe((response) => {
        this.anomalyDeviceSelector.dnsBlockCountLoading = false;
        this.anomalyDeviceSelector.dnsBlockCount = response?.anomalyBlacklist?.length;
      });
  }

  getSecurityEvents(date: string, callback: any = null): void {
    const limit = 500;
    this.securityPolicyService
      .events$(this.selector.current === 'aisecurity.unassignedDevices', date, limit, this.securityToken)
      .subscribe((response) => {
        if (response && response.length) {
          const responseEvents = response.map((event) => ({
            ...event,
            date: moment(event.createdAt).format('L LT'),
            device: this.selector.list.find((device) => event.eventData.mac === device.mac) || {
              name: 'aisecurity.unknown'
            }
          }));

          const lastEvent = moment(responseEvents[responseEvents.length - 1].createdAt);
          const month = moment().startOf('day').subtract(28, 'days');

          this.responseEvents = [...this.responseEvents, ...responseEvents];

          if (lastEvent.valueOf() < month.valueOf() || responseEvents.length < limit) {
            this.responseEvents = this.responseEvents.filter(
              (event) => moment(event.createdAt).valueOf() >= month.valueOf()
            );

            this.securityEvents = this.responseEvents;

            if (callback) {
              callback();
            }
          } else {
            this.getSecurityEvents(responseEvents[responseEvents.length - 1].createdAt, callback);
          }
        } else {
          this.securityEvents = [];

          if (callback) {
            callback();
          }
        }
      });
  }

  toggleOhpChart() {
    if (!this.ohpChartExpanded && this.ohpEvents === null) this.getOhpSecurityEvents();
    this.ohpChartExpanded = !this.ohpChartExpanded;
  }

  getOhpSecurityEvents(): void {
    if (!this.securityToken) return;
    if (!this.selector.list) return;
    this.securityPolicyService
      .eventStats$(
        this.devices.filter((device) => device.ohp.capable).map((device) => device.mac),
        'ohp',
        this.securityToken
      )
      .subscribe((response) => {
        if (response && response.dailyStats) {
          this.ohpEvents = response.dailyStats;
          this.ohpChartData = this.ohpEvents.map((data) => ({
            timestamp: data.localStartDate,
            data: this.reduceOhpEventData(data)
          }));
        } else {
          this.ohpEvents = [];
        }

        this.setOhpDeviceEvents();
      });
  }

  setOhpDeviceEvents() {
    this.ohpDevices = this.ohpDevices?.map((device) => ({
      ...device,
      hasEvents:
        this.ohpEvents
          ?.map((ohp) => ohp.events)
          .filter((events) => events.findIndex((event) => event.mac === device.mac) >= 0).length > 0
    }));
  }

  filterOhpEvents(device: IDevice | null) {
    this.ohpSelectedDevice = device;
    const events: typeof this.ohpEvents = JSON.parse(JSON.stringify(this.ohpEvents));

    if (!device) {
      this.ohpChartData = this.ohpEvents.map((data) => ({
        timestamp: data.localStartDate,
        data: this.reduceOhpEventData(data)
      }));
      return;
    }

    this.ohpChartData = events
      .map((ohpEvent) => {
        ohpEvent.events = ohpEvent.events.filter((event) => event.mac === device.mac);
        return ohpEvent;
      })
      .map((data) => ({
        timestamp: data.localStartDate,
        data: this.reduceOhpEventData(data)
      }));
  }

  reduceOhpEventData(data: ISecurityPolicyEventStats['dailyStats'][0]) {
    return data.events.reduce((map, event) => {
      if (event.type === 'adBlocking' || event.type === 'secureAndProtect' || event.type === 'iotProtect') {
        map[event.type] = { value: event.count };
      } else {
        map['contentAccess'] = { value: event.count };
      }
      return map;
    }, {});
  }

  initTogglers(): void {
    if (this.selector.current === 'aisecurity.unassignedDevices') {
      this.secureAndProtectItems = [
        {
          value: false,
          translation: 'aisecurity.disabled',
          selected: !this.securityPolicy.secureAndProtect
        },
        {
          value: true,
          translation: 'aisecurity.enabled',
          selected: this.securityPolicy.secureAndProtect
        }
      ];

      this.iotProtectItems = [
        {
          value: false,
          translation: 'aisecurity.disabled',
          selected: !this.securityPolicy.iotProtect
        },
        {
          value: true,
          translation: 'aisecurity.enabled',
          selected: this.securityPolicy.iotProtect
        }
      ];

      this.adBlockingItems = [
        {
          value: false,
          translation: 'aisecurity.disabled',
          selected: !this.securityPolicy.content.includes('adBlocking')
        },
        {
          value: true,
          translation: 'aisecurity.enabled',
          selected: this.securityPolicy.content.includes('adBlocking')
        }
      ];

      this.workAppropriateItems = [
        {
          value: false,
          translation: 'aisecurity.disabled',
          selected: !this.securityPolicy.content.includes('workAppropriate')
        },
        {
          value: true,
          translation: 'aisecurity.enabled',
          selected: this.securityPolicy.content.includes('workAppropriate')
        }
      ];

      this.adultAndSensitiveItems = [
        {
          value: false,
          translation: 'aisecurity.disabled',
          selected: !this.securityPolicy.content.includes('adultAndSensitive')
        },
        {
          value: true,
          translation: 'aisecurity.enabled',
          selected: this.securityPolicy.content.includes('adultAndSensitive')
        }
      ];

      this.teenagersItems = [
        {
          value: false,
          translation: 'aisecurity.disabled',
          selected: !this.securityPolicy.content.includes('teenagers')
        },
        {
          value: true,
          translation: 'aisecurity.enabled',
          selected: this.securityPolicy.content.includes('teenagers')
        }
      ];

      this.kidsItems = [
        {
          value: false,
          translation: 'aisecurity.disabled',
          selected: !this.securityPolicy.content.includes('kids')
        },
        {
          value: true,
          translation: 'aisecurity.enabled',
          selected: this.securityPolicy.content.includes('kids')
        }
      ];
    } else if (this.selector.current === 'aisecurity.allDevices') {
      this.secureAndProtectItems = [
        {
          value: false,
          translation: 'aisecurity.disabled',
          selected: !this.securityPolicy.secureAndProtect && this.securityPolicy.appliesToAllDevices.secureAndProtect
        },
        {
          value: true,
          translation: 'aisecurity.enabled',
          selected: this.securityPolicy.secureAndProtect && this.securityPolicy.appliesToAllDevices.secureAndProtect
        },
        {
          value: null,
          translation: 'aisecurity.custom',
          selected: !this.securityPolicy.appliesToAllDevices.secureAndProtect,
          unselectable: true
        }
      ];

      this.iotProtectItems = [
        {
          value: false,
          translation: 'aisecurity.disabled',
          selected: !this.securityPolicy.iotProtect && this.securityPolicy.appliesToAllDevices.iotProtect
        },
        {
          value: true,
          translation: 'aisecurity.enabled',
          selected: this.securityPolicy.iotProtect && this.securityPolicy.appliesToAllDevices.iotProtect
        },
        {
          value: null,
          translation: 'aisecurity.custom',
          selected: !this.securityPolicy.appliesToAllDevices.iotProtect,
          unselectable: true
        }
      ];

      this.adBlockingItems = [
        {
          value: false,
          translation: 'aisecurity.disabled',
          selected: !this.securityPolicy.adBlocking && this.securityPolicy.appliesToAllDevices.adBlocking
        },
        {
          value: true,
          translation: 'aisecurity.enabled',
          selected: this.securityPolicy.adBlocking && this.securityPolicy.appliesToAllDevices.adBlocking
        },
        {
          value: null,
          translation: 'aisecurity.custom',
          selected: !this.securityPolicy.appliesToAllDevices.adBlocking,
          unselectable: true
        }
      ];

      this.workAppropriateItems = [
        {
          value: false,
          translation: 'aisecurity.disabled',
          selected: !this.securityPolicy.workAppropriate && this.securityPolicy.appliesToAllDevices.workAppropriate
        },
        {
          value: true,
          translation: 'aisecurity.enabled',
          selected: this.securityPolicy.workAppropriate && this.securityPolicy.appliesToAllDevices.workAppropriate
        },
        {
          value: null,
          translation: 'aisecurity.custom',
          selected: !this.securityPolicy.appliesToAllDevices.workAppropriate,
          unselectable: true
        }
      ];

      this.adultAndSensitiveItems = [
        {
          value: false,
          translation: 'aisecurity.disabled',
          selected: !this.securityPolicy.adultAndSensitive && this.securityPolicy.appliesToAllDevices.adultAndSensitive
        },
        {
          value: true,
          translation: 'aisecurity.enabled',
          selected: this.securityPolicy.adultAndSensitive && this.securityPolicy.appliesToAllDevices.adultAndSensitive
        },
        {
          value: null,
          translation: 'aisecurity.custom',
          selected: !this.securityPolicy.appliesToAllDevices.adultAndSensitive,
          unselectable: true
        }
      ];

      this.teenagersItems = [
        {
          value: false,
          translation: 'aisecurity.disabled',
          selected: !this.securityPolicy.teenagers && this.securityPolicy.appliesToAllDevices.teenagers
        },
        {
          value: true,
          translation: 'aisecurity.enabled',
          selected: this.securityPolicy.teenagers && this.securityPolicy.appliesToAllDevices.teenagers
        },
        {
          value: null,
          translation: 'aisecurity.custom',
          selected: !this.securityPolicy.appliesToAllDevices.teenagers,
          unselectable: true
        }
      ];

      this.kidsItems = [
        {
          value: false,
          translation: 'aisecurity.disabled',
          selected: !this.securityPolicy.kids && this.securityPolicy.appliesToAllDevices.kids
        },
        {
          value: true,
          translation: 'aisecurity.enabled',
          selected: this.securityPolicy.kids && this.securityPolicy.appliesToAllDevices.kids
        },
        {
          value: null,
          translation: 'aisecurity.custom',
          selected: !this.securityPolicy.appliesToAllDevices.kids,
          unselectable: true
        }
      ];
    } else {
      this.secureAndProtectItems = [
        {
          value: false,
          translation: 'aisecurity.disabled',
          selected: !this.securityPolicy.secureAndProtect
        },
        {
          value: true,
          translation: 'aisecurity.enabled',
          selected: this.securityPolicy.secureAndProtect
        }
      ];

      this.iotProtectItems = [
        {
          value: false,
          translation: 'aisecurity.disabled',
          selected: !this.securityPolicy.iotProtect
        },
        {
          value: true,
          translation: 'aisecurity.enabled',
          selected: this.securityPolicy.iotProtect
        }
      ];

      this.adBlockingItems = [
        {
          value: false,
          translation: 'aisecurity.disabled',
          selected: !this.securityPolicy.adBlocking
        },
        {
          value: true,
          translation: 'aisecurity.enabled',
          selected: this.securityPolicy.adBlocking
        }
      ];

      this.workAppropriateItems = [
        {
          value: false,
          translation: 'aisecurity.disabled',
          selected: !this.securityPolicy.workAppropriate
        },
        {
          value: true,
          translation: 'aisecurity.enabled',
          selected: this.securityPolicy.workAppropriate
        }
      ];

      this.adultAndSensitiveItems = [
        {
          value: false,
          translation: 'aisecurity.disabled',
          selected: !this.securityPolicy.adultAndSensitive
        },
        {
          value: true,
          translation: 'aisecurity.enabled',
          selected: this.securityPolicy.adultAndSensitive
        }
      ];

      this.teenagersItems = [
        {
          value: false,
          translation: 'aisecurity.disabled',
          selected: !this.securityPolicy.teenagers
        },
        {
          value: true,
          translation: 'aisecurity.enabled',
          selected: this.securityPolicy.teenagers
        }
      ];

      this.kidsItems = [
        {
          value: false,
          translation: 'aisecurity.disabled',
          selected: !this.securityPolicy.kids
        },
        {
          value: true,
          translation: 'aisecurity.enabled',
          selected: this.securityPolicy.kids
        }
      ];
    }
  }

  updateSettings(setting: any, mode: boolean): void {
    this.mixpanel.storeEvent('AI_SECURITY_UPDATE_POLICY');

    const settings: DeepPartial<ISecurityPolicy> = {};

    if (this.selector.current === 'aisecurity.allDevices') {
      settings['appliesToAllDevices'] = {};
    }

    switch (setting) {
      case 'secureAndProtect':
        settings[setting] = mode;

        if (!mode) {
          settings['iotProtect'] = mode;

          if (this.selector.current === 'aisecurity.allDevices') {
            settings['appliesToAllDevices']['iotProtect'] = true;
          }
        }
        break;
      case 'iotProtect':
        settings[setting] = mode;

        if (mode) {
          settings['secureAndProtect'] = mode;

          if (this.selector.current === 'aisecurity.allDevices') {
            settings['appliesToAllDevices']['secureAndProtect'] = true;
          }
        }
        break;
      case 'adBlocking':
        if (mode) {
          if (this.securityPolicy['content'].indexOf(setting) < 0) {
            settings['content'] = [...this.securityPolicy['content'], setting];
          }
        } else {
          if (this.securityPolicy['content'].indexOf(setting) > -1) {
            settings['content'] = this.securityPolicy['content'].filter((content: string) => content !== setting);
          }
        }
        break;
      default:
        if (mode) {
          settings['content'] = [setting];
        } else {
          settings['content'] = [];
        }

        if (this.selector.current === 'aisecurity.allDevices') {
          settings['appliesToAllDevices'] = {
            teenagers: true,
            kids: true,
            adultAndSensitive: true,
            workAppropriate: true
          };
        }

        if (this.securityPolicy['content'].indexOf('adBlocking') > -1) {
          settings['content'].push('adBlocking');
        }
    }

    if (this.selector.current === 'aisecurity.allDevices') {
      settings['appliesToAllDevices'][setting] = true;
    }

    this.securityPolicyService
      .editSecurityPolicy$(
        this.selector.current === 'aisecurity.allDevices'
          ? 'all'
          : this.selector.current === 'aisecurity.unassignedDevices'
          ? 'unassigned'
          : this.selector.mac,
        settings
      )
      .subscribe(() => {
        this.getSecurityPolicy();
      });
  }

  securitySettingToggle(mode: boolean): void {
    if (!mode && this.securityPolicy.iotProtect) {
      this.modalService
        .showDialog('aisecurity.disableAdvIOTProtMsg', 'aisecurity.disableAdvIOTProt', {
          buttons: [
            { style: 'tertiary light', value: 'booleanEnum.false' },
            { style: 'super-primary', value: 'booleanEnum.true' }
          ]
        })
        .subscribe((response) => {
          if (response.item.value === 'booleanEnum.true') {
            this.updateSettings('secureAndProtect', mode);
          } else {
            this.initTogglers();
          }
        });
    } else {
      this.updateSettings('secureAndProtect', mode);
    }
  }

  unsubscribeTimer(): void {
    this.togglerMessage = 'aisecurity.enableAccess';
    this.securityToken = null;
    this.securityPolicy = null;
    this.securityEvents = null;
    this.responseEvents = null;
    this.filterEnabled = false;
    this.filterType = null;
    this.dayView = null;
    this.selector.current = 'aisecurity.allDevices';
    this.selector.mac = null;
    this.deviceAssignedToPerson = false;
    this.closeModal();

    if (this.timer.handler) {
      clearInterval(this.timer.handler);
      this.timer.handler = null;
      this.timer.mins = null;
      this.timer.secs = null;
      this.timer.show = false;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribeTimer();

    this.subscriptions.forEach((subscription: any) => subscription.unsubscribe());

    if (this.policySubscription) {
      this.policySubscription.unsubscribe();
    }
  }
}
