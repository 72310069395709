import { Component, OnChanges, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { Store } from '@ngrx/store';
import { selectOffline } from 'src/app/store/customer/customer.selectors';
import { selectCapabilities } from 'src/app/store/customer/capabilities.selector';
import {
  IParsedCapabilities,
  IUnparsedCapabilities,
  ICapabilitiesFilter,
  IFilterValues
} from './capabilities.interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'capabilities',
  templateUrl: './capabilities.component.html',
  styleUrls: ['./capabilities.component.scss']
})
export class CapabilitiesComponent implements OnChanges, OnInit {
  expand: boolean = false;
  parsedCapabilities: IParsedCapabilities[] = [];
  capabilities: IUnparsedCapabilities = {};
  isOffline: boolean = false;
  searchReady: boolean = false;
  capabilitiesReady: boolean = false;
  filterType: string = 'all';
  filterItems: ICapabilitiesFilter[];
  @Input()
  open: number = 0;

  @Output()
  toggle = new EventEmitter();

  @Output()
  filter = new EventEmitter();

  @Output()
  clearFilter = new EventEmitter<{ section: string }>();

  constructor(private mixpanel: MixpanelService, private translate: TranslateService, private store: Store) {}

  ngOnInit(): void {
    this.store
      .select(selectOffline)
      .pipe(untilDestroyed(this))
      .subscribe((offlineState) => {
        this.isOffline = offlineState;
      });
    this.store
      .select(selectCapabilities)
      .pipe(untilDestroyed(this))
      .subscribe((capabilities) => {
        if (capabilities && !this.capabilitiesReady) {
          this.capabilities = capabilities;
          this.viewSetFilter('all');
          this.capabilitiesReady = true;
        }
      });
  }

  ngOnChanges(changes: any): void {
    this.expand = changes.open.currentValue;
  }

  setupSearch(capabilities: IParsedCapabilities[]): void {
    if (!this.searchReady) {
      capabilities.forEach((capability: IParsedCapabilities) => {
        this.filter.emit({
          section: 'capabilities',
          property: capability.key,
          translation: capability.sortStr
        });
      });
      this.searchReady = true;
    }
  }

  parseCapabilities(): IParsedCapabilities[] {
    const sorted: IParsedCapabilities[] = [];
    Object.keys(this.capabilities).forEach((key) => {
      this.capabilities[key].sortStr = this.translate.instant(this.capabilities[key].translate);
      this.capabilities[key].key = key;
      sorted.push(this.capabilities[key]);
    });
    sorted.sort((a, b) => (a.sortStr.toLowerCase() > b.sortStr.toLowerCase() ? 1 : -1));
    this.setupSearch(sorted);
    return sorted;
  }

  toggleExpand(): void {
    this.toggle.emit(!this.expand);

    if (!this.expand) {
      this.mixpanel.storeEvent('CONFIGURATION_CAPABILITIES_SCREEN');
    }
  }

  viewSetFilter(value: IFilterValues): void {
    this.filterItems = [
      {
        value: 'all',
        translation: 'configurations.capabilities.all',
        selected: value === 'all'
      },
      {
        value: 'capable',
        translation: 'configurations.capabilities.capable',
        selected: value === 'capable'
      },
      {
        value: 'notCapable',
        translation: 'configurations.capabilities.notCapable',
        selected: value === 'notCapable'
      },
      {
        value: 'compressed',
        translation: 'configurations.capabilities.compressed',
        selected: value === 'compressed'
      }
    ];
    this.filterType = value;
    this.parsedCapabilities = this.parseCapabilities();

    if (value === 'capable') {
      this.parsedCapabilities = this.parsedCapabilities.filter((capability) => capability.capable === true);
    } else if (value === 'notCapable') {
      this.parsedCapabilities = this.parsedCapabilities.filter((capability) => capability.capable === false);
    }
  }
}
